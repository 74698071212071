import { Navbar } from "../components/custom/Navbar"
import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import { Button } from "../components/ui/button"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import { Separator } from "../components/ui/separator"
import AccountNavigation from "../components/custom/AccountNavigation/AccountNavigation"
import Footer from "../components/custom/Footer"

const Account = () => {
    let {logoutUser} = useContext(AuthContext)

    return (
        <>
            <div className=" flex flex-col">
                <div className="bg-white sticky top-0 z-10">
                    <Navbar />
                </div>
                <div className="flex flex-col h-full">
                    <h1 className="text-md sticky top-0 p-[15px]">
                        Account
                    </h1>
                    <div className="h-full">
                        <AccountNavigation/>
                    </div>
                    
                </div>
                
                <div className="mt-40">
                

                </div>
                
            </div>
            <Footer  />
        </>
        
        )
    }

  export default Account