import { Navbar } from "../components/custom/Navbar"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../context/AuthContext"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import DashboardCardSummary from "../components/custom/Dashboard/DashboardCardSummary"
import { Card, CardContent, CardHeader } from "../components/ui/card"
import { Euro } from "lucide-react"
import { ShoppingCart } from "lucide-react"
import DashboardChart from "../components/custom/Dashboard/DashboardChart"
import DashboardSales from "../components/custom/Dashboard/DashboardSales"
import PageTitle from "../components/custom/ProfileNavigation/PageTitle"
import { Check } from "lucide-react"
import Footer from "../components/custom/Footer"

const Dashboard = () => {
  let { server_address, authTokens, user} = useContext(AuthContext)
  let [data, setData] = useState()

  console.log(user.username)
  useEffect(() => {
    getData()
  }, [])

  let getData = async () => {
    let response = await fetch(server_address+"/user/dashboard?", {
      method:'GET',
      headers:{
          'Content-Type':'application/json',
          'Authorization': 'Bearer ' + String(authTokens?.access)
      }
    })

  
    if(response.status === 200)
    {
        let data = await response.json()
        setData(data)
        

    } 
  }

  const test = [
    {
      name: "Jan",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Feb",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Mar",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Apr",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "May",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Jun",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Jul",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Aug",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Sep",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Oct",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Nov",
      total: Math.floor(Math.random() * 5000) + 1000
    },
    {
      name: "Dec",
      total: Math.floor(Math.random() * 5000) + 1000
    }
  ];
  const sales = [
    {

    }
  ]
  return (
    <div >
      <div className="bg-white sticky top-0 z-10">
        <Navbar />
      </div>
      <div className="flex">
        <div className="sticky top-0 z-1 self-start h-dvh">
          <ProfileNavigation />
        </div>

        <div className="w-full flex flex-col p-[15px] sm:p-6 ">
          <div className="sticky top-0 z-1 self-start">
            <PageTitle title="Dashboard" />
          </div>
          {data && <section className="grid w-full grid-cols-1 gap-6 gap-x-8 mt-3  sm:grid-cols-2 xl:grid-cols-4">
            <DashboardCardSummary label={"Total purchases"} amount={"€"+data.total_purchases} description={"+20.1% from last month"} icon={<ShoppingCart />} />
            <DashboardCardSummary label={"Total sales"} amount={"€"+data.total_sales} description={"+34.8% from last month"} icon={<Euro />} />
            <DashboardCardSummary label={"Buyer refs"} amount={data.buyer_refs} description={"more than 67.4% of users"} icon={<Check />} />
            <DashboardCardSummary label={"Seller refs"} amount={data.seller_refs} description={"more than 48.2% of users"} icon={<Check />} />
            <div className="sm:col-span-2">
              <Card className="h-full">
                <CardHeader>
                  Volume overview
                </CardHeader>
                <CardContent>
                  <DashboardChart data={data.graph_data} />
                </CardContent>
              </Card>
            </div>
            <div className="sm:col-span-2">
              <Card className="h-full">
                <CardHeader>
                  Recent activity
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col gap-7">
                    {console.log(data.recent_activity)}
                    {data.recent_activity.map((activity) => (
                      <DashboardSales 
                        name={activity.title} 
                        amount={activity.deal_type === "Ask" ?
                          activity.creator === user.username ? '+ €' + activity.price : '- €' + activity.price :
                          activity.creator === user.username ? '+ €' + activity.price : '- €' + activity.price
                        } 
                        size={activity.size} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </section>}
        </div>
      </div>
      <div className="mt-36">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Dashboard