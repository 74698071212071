// import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "../CustomAccordion";
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "./CustomAccordion";
import { Button } from "../ui/button";
import { Check } from "lucide-react";

export function EditPayments({selectedPaymentMethods, paymentMethods, setPaymentMethods}) {
    function deleteCourier(paymentMethod){
        setPaymentMethods(selectedPaymentMethods.filter((a) => a === paymentMethod ? false : true))
        
    }
    
    return(

        <Accordion type="single" collapsible>
            <AccordionItem value="item-1" >
                    <AccordionTrigger selectedSize={selectedPaymentMethods.length === 1  && selectedPaymentMethods[0] === "Default" && "Default"} >
                        <div className="flex items-center gap-4 text-sm">
                            <p> Payment methods </p>
                            {selectedPaymentMethods.length !== 0 && <Check size={16}/>}
                        </div>
                    </AccordionTrigger>
                <AccordionContent>
                    <div className="flex flex-col gap-2">
                        {paymentMethods && paymentMethods.map((paymentMethod, index) => (
                            <div className="flex items-center gap-3">
                                {selectedPaymentMethods.includes(paymentMethod) ? 
                                <button 
                                    key={index}
                                    className="w-[16px] h-[16px] bg-orange rounded-full" 
                                    onClick={() => {deleteCourier(paymentMethod)}}
                                />
                                : 
                                <button 
                                    className="w-[16px] h-[16px] bg-background border border-white rounded-full"
                                    onClick={() => {
                                        setPaymentMethods([
                                            ...selectedPaymentMethods,
                                            paymentMethod
                                        ])}} 
                                />}

                                <p>{paymentMethod}</p>
                            </div>
                        ))}
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>

    )
}