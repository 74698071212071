import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
 } from "src/components/ui/table"

import { Button } from "src/components/ui/button"
import { ArrayListDropdown } from "../ArrayListDropdown"
import { ArrowUpDown } from "lucide-react"
import { useEffect, useState } from "react"
import { AscDesDropdown } from "../AscDesDropdown"
import { ArrowDown } from "lucide-react"
import { ArrowUp } from "lucide-react"
import { ChevronsUpDown } from "lucide-react"
import Pagination from "../Pagination"
import { Link } from "react-router-dom"

function UserTableSales({sales, setSales, page, totalPages, setPage, limit, setLimit}){
    let [priceSorting, setPriceSorting] = useState()

    useEffect(() => {
      sortMarketplacePrice()
    }, [])

    useEffect(() => {
      sortMarketplacePrice()
    }, [priceSorting])
    
    let sortMarketplacePrice = () => {
      if(priceSorting == "Asc"){
        let sortedMarketplace = sales.sort((a, b) => a.price - b.price);
        setSales(sortedMarketplace);
      }

      else if(priceSorting == "Desc"){
        let sortedMarketplace = sales.sort((a, b) => b.price - a.price);
        setSales(sortedMarketplace);
      }
    }


    return(
      <div className="flex flex-col gap-4">
      <div className="w-[100%] rounded-lg border border-gray-200 dark:border-gray-700">
      <Table>
        <TableHeader>
          <TableRow className="bg-[#18191B] hover:bg-[#18191B]">

            <TableHead className="">
              <div className="flex justify-center">
                Sku
              </div>  
            </TableHead>
            <TableHead className="">
              <div className="flex justify-center">
                Size
              </div>  
            </TableHead>
            <TableHead className="">
              <div className="flex justify-center">
                <Button 
                  variant="ghost" 
                  className="w-[100%] px-4"
                  onClick={() => {
                    if(priceSorting == "Asc"){
                      setPriceSorting("Desc")
                    }
                    else{
                      setPriceSorting("Asc")
                    }
                  }}
                  >
                  Price
                  {priceSorting == "Asc" && <ArrowDown className="ml-2 h-4 w-4"/>}
                  {priceSorting == "Desc" && <ArrowUp className="ml-2 h-4 w-4"/>}
                  {!priceSorting && <ChevronsUpDown className="ml-2 h-4 w-4"/>}

                </Button>
                {/* {<AscDesDropdown name="Price"/>} */}
              </div>  
            </TableHead>

            <TableHead>
              <div className="flex justify-center">
                Buyer
              </div> 
            </TableHead>
            <TableHead className="">
              <div className="flex justify-center">
                Minimum Refs
              </div>
            </TableHead>
            
            <TableHead className="hidden md:table-cell">
              <div className="flex justify-center min-w-[175px]">
                Couriers accepted
              </div>
            </TableHead>

            <TableHead className="hidden md:table-cell">
              <div className="flex justify-center min-w-[220px]">
                Payment methods accepted
              </div>
            </TableHead>
            <TableHead className="">
              <div className="flex justify-center">
                Date
              </div>
            </TableHead>
            <TableHead className="">
              <div className="flex justify-center">
                Status
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sales.map((offer) => (
            <TableRow>

              <TableCell>
                <div className="flex justify-center underline">
                  <Link to={"/shoes/"+offer.name}>
                    {offer.sku}
                  </Link>
                </div>  
              </TableCell>
              <TableCell>
                <div className="flex justify-center">
                  {offer.size}
                </div>  
              </TableCell>
              
              <TableCell >
                <div className="flex justify-center">
                €{offer.price}
                </div>  
              </TableCell>

              <TableCell>
                <div className="flex justify-center">
                  {offer.deals_type == "Ask" ? 
                  offer.acceptor ? 
                  <Link className="underline" to={"/user/"+offer.acceptor}>
                    {offer.acceptor}
                  </Link> : 
                  "deleted user"
                   : 
                   offer.creator ? 
                  <Link className="underline" to={"/user/"+offer.creator}>
                  {offer.creator}
                  </Link> : 
                  "deleted user"
                  }
                </div>  
              </TableCell>
              <TableCell>
                <div className="flex justify-center">
                  {offer.minimum_refs}
                </div>
              </TableCell>
            

              
              <TableCell className="hidden md:table-cell">
                <div className="flex justify-center">
                {<ArrayListDropdown name="couriers" list={offer.couriers_accepted}/>}
                </div>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <div className="flex justify-center">
                  <ArrayListDropdown name="payment methods" list={offer.payments_accepted}/>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex justify-center">
                  <span>{new Date(offer.acceptation_date).getDate()}/{new Date(offer.acceptation_date).getMonth() + 1}/{new Date(offer.acceptation_date).getFullYear()}</span>
                </div>  
              </TableCell>

              <TableCell>
                <div className="flex justify-center">
                {offer.status}
                </div>  
              </TableCell>
              
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    <div className="flex flex-row-reverse">
        <Pagination page={page} totalPages={totalPages} setPage={setPage} limit={limit} setLimit={setLimit}/>
      </div>
    </div>
  )
}

export default UserTableSales