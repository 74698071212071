import { Navbar } from "../components/custom/Navbar"
import { useContext, useState, useEffect } from "react"
import AuthContext from "../context/AuthContext"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import UserTableBids from "../components/custom/tables/userTables/UserTableBids"
import PageTitle from "../components/custom/ProfileNavigation/PageTitle"
import Footer from "../components/custom/Footer"


const BuyingActive = () => {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [bids, setBids] = useState()
    let [page, setPage] = useState(1)
    let [totalPages, setTotalPages] = useState()
    let [limit, setLimit] = useState(10)
    let [refresh, setRefresh] = useState(false)
    
    useEffect(() => {
        getMarketplace()
    }, [page, limit, refresh])
    
    let getMarketplace = async () => {
        let response = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "user",
            deals_type: "Bid",
            status: "active",
            limit: limit,
            page: page
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setBids(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalPages(data.pagination.total_pages) : setTotalPages(1)

        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    return (
        <div >
            <div className="bg-white sticky top-0 z-10">
                <Navbar />
            </div>
            <div className="flex">
                <div className="sticky top-0 z-1 self-start h-dvh">
                    <ProfileNavigation />
                </div>
                <div className="p-[15px] sm:p-6 flex-col w-full overflow-auto">
                    <PageTitle title="Active bids"/>
                    <div className="mt-3 ">
                        {bids && <UserTableBids bids={bids} setBids={setBids} page={page} setPage={setPage} totalPages={totalPages} limit={limit} setLimit={setLimit} refresh={refresh} setRefresh={setRefresh}/>}
                    </div>
                    
                       
                    
                    
                            
                </div>
            </div>
            <div className="mt-[500px]">
                <Footer></Footer>
            </div>
        </div>
        )
    }

  export default BuyingActive