import { Button } from "../../ui/button"

const timeframes = [
    "1M",
    "3M",
    "6M",
    "9M",
    "YTD",
    "1Y",
    "ALL"
]

export function SelectTimeframe({timeframe, setTimeframe}) {
    return(
        <div className="flex flex-wrap">


            {timeframes.map((tf, index) => (
                timeframe === tf ? 
                <Button variant="orange" className="rounded-none font-extrabold w-[85px] h-[27px]" onClick={() => setTimeframe(tf)}>
                    {tf}
                </Button> : 
                <Button variant="gray" className="rounded-none font-extrabold w-[85px] h-[27px]" onClick={() => setTimeframe(tf)}>
                    {tf}
                </Button>
            ))}

                
        </div>
    )
}

// {timeframes.map((tf, index) => (
//     timeframe === tf ? <Button className="rounded-none font-extrabold w-[75px] h-[30px]">
//     {tf}
// </Button>
//     ))}