import { useEffect, useState } from "react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/components/ui/dialog"
import { Input } from "src/components/ui/input"
import { Label } from "src/components/ui/label"

import { Button } from "src/components/ui/button"
import AuthContext from "src/context/AuthContext"
import { useContext } from "react"
import { ButtonLoading } from "../ButtonLoading"


export function ReportProblem({offer, refresh, setRefresh}) {

    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [open, setOpen] = useState()
    let [next, setNext] = useState(false)
    let [token, setToken] = useState()
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(false)

    let report = async () => {
      setLoading(true)
      let response = await fetch(server_address+'/reports', {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + String(authTokens?.access)
                },
                body:JSON.stringify({
                    'marketplace_id': offer.id,
                })
            })
            
            if (response.status === 200)
            {
              let data = await response.json()
              setError(false)
              setNext(true)
              setLoading(false)
              setToken(data.token)
            }

            else{
              setLoading(false)
              setError(true)
            }

    }

    return (

        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
            <Button variant="orange" className="font-bold sm:w-[200px]">
                Report problem
            </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
                <DialogTitle className="text-md">
                  {
                    next ? 
                    <h1>
                      Contact us!
                    </h1> :
                    <h1>
                      Report an issue with a deal
                    </h1>
                  }
                </DialogTitle>
                <DialogDescription>
                  {next == false ? 
                  <div>
                    Before opening a case make sure to check the rules of the marketplace and try to reach out to the buyer/seller. <br />
                    if you think the user has violated the rules click continue, this will open a case and it will be assigned a code
                  </div> : 
                    <div>
                    The deal has been marked as issued. Open a ticket in our <a href="https://discord.gg/aBAu8wCGrq" className="underline font-bold"> discord server </a>  with this code. <br />

                  </div>
                  }
                                 
                </DialogDescription>
            </DialogHeader>
            <div className="flex justify-center sm:block font-bold text-md">
              {token}
            </div> 

            <DialogFooter>
              {loading ? 
              <ButtonLoading /> : 
              next ? <Button onClick={() => {
                setOpen(false)
                setRefresh(true)}
              }> Close </Button> :  
              <Button type="submit" onClick={() => {report()}} >Continue</Button>
              }
              
            </DialogFooter>
            </DialogContent>
        </Dialog>
    )
  }