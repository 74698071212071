
   
import { Button } from "../../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"
import { ArrowUpDown } from "lucide-react"
import { ScrollableList } from "./ScrollableList"
import { ArrowDown } from "lucide-react"
import { Expand } from "lucide-react"
import { Maximize2 } from "lucide-react"
import { Minimize2 } from "lucide-react"
import { useState } from "react"

  export function ArrayListDropdown({name, list}) {
    let [isOpen, setIsOpen] = useState(false)
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="border-none" onClick={() => (setIsOpen(!isOpen))}>
            {/* Show {name} */}
            Show
            {/* <ArrowDown className="ml-2 h-4 w-4"/> */}
            {/* {isOpen ? <Minimize2 className="ml-2 h-4 w-4"/> : <Maximize2 className="ml-2 h-4 w-4"/>} */}
            
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          {/* <DropdownMenuLabel>Accepted {name}</DropdownMenuLabel> */}
          <ScrollableList list={list}/>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }