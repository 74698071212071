import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import Login from "./pages/Login";
import LandingPage from "./pages/LandingPage";
import Profile from "./pages/Profile";
import ErrorPage from "./pages/ErrorPage";
import Register from "./pages/Register";
import ProductPage from "./pages/ProductPage";
import ResetPassword from "./pages/ResetPassword";
import Help from "./pages/Help";
import Buy from "./pages/Buy";
import Sell from "./pages/Sell";
import Search from "./pages/Search";
import BuyingActive from "./pages/BuyingActive";
import BuyingCompleted from "./pages/BuyingCompleted";
import SellingActive from "./pages/SellingActive";
import SellingCompleted from "./pages/SellingCompleted";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import ConfirmProfile from "./pages/ConfirmProfile";
import ChangePassword from "./pages/ChangePassword";
import Home from "./pages/Home";
import User from "./pages/User";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Rules from "./pages/Rules";
import PreLaunch from "./pages/PreLaunch";
import { useState } from "react";
import Password from "./pages/Password";

function App() {
  
  let [launch, setLaunch] = useState(true)

  return (
    <div className="font-inter">
      <BrowserRouter>
        <AuthProvider>
          {launch ? <Routes>
            <Route path='/home'
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path='/profile'
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path='/buying/active'
              element={
                <ProtectedRoute>
                  <BuyingActive />
                </ProtectedRoute>
              }
            />
            <Route path='/buying/history'
              element={
                <ProtectedRoute>
                  <BuyingCompleted />
                </ProtectedRoute>
              }
            />
            <Route path='/selling/active'
              element={
                <ProtectedRoute>
                  <SellingActive />
                </ProtectedRoute>
              }
            />
            <Route path='/selling/history'
              element={
                <ProtectedRoute>
                  <SellingCompleted />
                </ProtectedRoute>
              }
            />
            <Route path='/dashboard'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path='/shoes/:name'
              element={
                <ProtectedRoute>
                  <ProductPage />
                </ProtectedRoute>
              }
            />
            <Route path='/help'
              element={
                <ProtectedRoute>
                  <Help />
                </ProtectedRoute>
              }
            />
            <Route path='/buy'
              element={
                <ProtectedRoute>
                  <Buy />
                </ProtectedRoute>
              }
            />
            <Route path='/sell'
              element={
                <ProtectedRoute>
                  <Sell />
                </ProtectedRoute>
              }
            />
            <Route path='/search'
              element={
                <ProtectedRoute>
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route path='/account'
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route path='/changepassword'
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route path='/user/:username'
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route path='/terms'
              element={
                <ProtectedRoute>
                  <Terms />
                </ProtectedRoute>
              }
            />
            <Route path='/privacy'
              element={
                <ProtectedRoute>
                  <Privacy />
                </ProtectedRoute>
              }
            />
            <Route path='/rules'
              element={
                <ProtectedRoute>
                  <Rules />
                </ProtectedRoute>
              }
            />

            <Route index element={<LandingPage />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/confirmprofile" element={<ConfirmProfile />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path='/password/exlusiveaccess$' element={<LandingPage />} />
            <Route path='*' element={<ErrorPage />} />
          
          </Routes> :


          <Routes>
            <Route path='/password/exlusiveaccess$' element={<Password setLaunch={setLaunch}/>} />
            <Route path="/confirmprofile" element={<ConfirmProfile />} />
            <Route index element={<PreLaunch setLaunch={setLaunch}/>} />
            <Route path='*' element={<PreLaunch setLaunch={setLaunch}/>} />
          </Routes>

          }
          
        </AuthProvider>
      
      </BrowserRouter>

      
      

    </div>
  );
}

export default App;
