import { Navbar } from "../components/custom/Navbar"
import { useParams } from "react-router-dom";
import { SelectSize } from "../components/custom/SelectSize";
import { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SelectCouriers } from "../components/custom/SelectCouriers";
import { SelectPaymentMethods } from "../components/custom/SelectPaymentMethods";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import { Check } from "lucide-react";
import Footer from "../components/custom/Footer";
import { ButtonLoading } from "../components/custom/ButtonLoading";


const Buy = () => {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    const location = useLocation();
    const size = new URLSearchParams(location.search).get('size')
    const name = new URLSearchParams(location.search).get('name')
    let [sizes, setSizes] = useState()
    let [sizeSelected, setSizeSelected] = useState(size)
    let [couriers, setCouriers] = useState()
    let [couriersSelected, setCouriersSelected] = useState([])
    let [paymentMethods, setPaymentMethods] = useState()
    let [paymentMethodsSelected, setPaymentMethodsSelected] = useState([])
    let [shoe, setShoe] = useState()
    let [sellerRefs, setSellerRefs] = useState()
    let [price, setPrice] = useState()
    let deal_type = "Bid"
    let [loading, setLoading] = useState(false)
    let [insertSuccessfully, setInsertSuccessfully] = useState(false)
    let [error, setError] = useState(false)
    let navigate = useNavigate()
    
    useEffect(() => {
        getShoe()
        getCouriers()
        getPaymentMethods()
        getDefaultCouriers()
        getDefaultPaymentMethods()
    }, [])

    useEffect(() => {
        if (shoe){
            getSizes()
        }
    }, [shoe])

    let getShoe = async () => {
        
        let response = await fetch(server_address+'/shoes?' + new URLSearchParams({
            name: name,
            page: 1,
            limit: 1,
            brand: '',
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setShoe(data)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getSizes = async () => {
        
        let response = await fetch(server_address+'/sizes?' + new URLSearchParams({
            name: shoe.name,
            category: "",
            brand: "",
            
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let sizesArray = []
            data.map((size) => {
                sizesArray.push(size.size)
            })
            setSizes(sizesArray)
            console.log(sizesArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getCouriers = async () => {
        
        let response = await fetch(server_address+'/couriers', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let couriersArray = []
            data.map((courier) => {
                couriersArray.push(courier.name)
            })
            setCouriers(couriersArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getDefaultCouriers = async () => {
        
        let response = await fetch(server_address+'/couriers/default', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let defaultCouriersArray = []
            data.map((courier) => {
                defaultCouriersArray.push(courier.courier.name)
            })
            
            setCouriersSelected(defaultCouriersArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getPaymentMethods = async () => {
        
        let response = await fetch(server_address+'/paymentmethods', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let paymentMethodsArray = []
            data.map((paymentMethod) => {
                paymentMethodsArray.push(paymentMethod.name)
            })
            setPaymentMethods(paymentMethodsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getDefaultPaymentMethods = async () => {
        
        let response = await fetch(server_address+'/paymentmethods/default', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let defaultPaymentMethodsArray = []
            data.map((paymentMethod) => {
                defaultPaymentMethodsArray.push(paymentMethod.payment_method.name)
            })
            setPaymentMethodsSelected(defaultPaymentMethodsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }


    let insertBid = async () => {
        setError(false)
        setLoading(true)

        let response = await fetch(server_address+'/marketplace/add', {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            },
            body:JSON.stringify({
                'name': name, 
                'deal_type': deal_type,
                'size': sizeSelected,
                'price': price,
                'minimum_refs': sellerRefs,
                'couriers_accepted': couriersSelected,
                'payment_methods_accepted': paymentMethodsSelected,
            })
        })
        
        if(response.status === 200){
            setInsertSuccessfully(true)
            setLoading(false)
            navigate("/shoes/" + name)
        }

        else{
            setLoading(false)
            setError(true)
        }
    }

    


    return (
        <div className="bg-background">
        
            <div className="sticky top-0 z-10">
                <Navbar />
            </div>
            

            <div className="flex flex-col xl:flex-row min-h-dvh">

                <div className="bg-white w-[100%] p-[15px] sm:px-[40px] sm:pt-5">
                    <div className=" text-black">
                        <h1 className="font-bold leading-tight text-md sm:text-lg "> {shoe && shoe.title} </h1>
                        <h2 className=" text-sm"> {shoe && shoe.sku} </h2>
                        <span className=" text-sm"> {shoe && shoe.category} </span > 
                    </div>
                    <div className=" bg-white ">
                       <div className=" bg-white ">
                        <img 
                            src={"https://www.finedeal.eu/images/big/"+name+".jpg"}
                            alt="shoe photo"
                            className="mx-auto"
                            >
                        </img>
                    </div>
                    </div>
                </div>

                <div className="bg-background w-[100%] p-[15px] sm:px-[40px] pt-5">
                    <div className="flex ">
                        <h1 className="text-md sm:text-lg font-bold">
                            Enter your Bid
                        </h1>
                    </div>
                    <div className="flex flex-col gap-5 mt-8 sm:mt-10">
                        {sizes && <SelectSize selectedSize={sizeSelected} sizes={sizes} selectSize={setSizeSelected} className="text-white"/>}
                        {couriers && <SelectCouriers selectedCouriers={couriersSelected} couriers={couriers} setCouriers={setCouriersSelected} className="text-white"/>}
                        {paymentMethods && <SelectPaymentMethods selectedPaymentMethods={paymentMethodsSelected} paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethodsSelected} className="text-white"/>}
                    </div>

                    <div className="grid grid-cols-2 gap-y-5 gap-x-7 mt-14">
                        <div className="flex items-center">
                            <Label className="text-sm sm:text-md" htmlFor="refs">Minimum number of seller refs</Label>
                        </div>

                        <div className="flex items-center w-[100%] gap-4">
                            {sellerRefs ? <Check /> : <Check className="invisible"/>}
                            <Input className="h-[60px]" 
                                    id="refs" 
                                    type="number" 
                                    min="0" 
                                    placeholder="0"
                                    onChange={(e) => setSellerRefs(e.target.value)}
                            />
                        </div>
                        
                        <div className="flex items-center">
                            <Label className="text-sm sm:text-md" htmlFor="price">Price willing to pay (shipped)</Label>
                        </div>

                        <div className="flex items-center w-[100%] gap-4">
                            {price && price !== 0 ? <Check /> : <Check className="invisible"/>}
                            <Input className="w-[100%] h-[60px]" 
                                    id="price" 
                                    min="1" 
                                    type="number"
                                    placeholder="€"
                                    onChange={(e) => setPrice(e.target.value)}
                                    value={price}
                            />

                            
                        </div>
                        
                        

     
                    </div>

                    <div className="flex flex-row-reverse mt-14">
                        <div className="flex flex-col gap-3 w-[100%] sm:w-[60%]">

                            {loading ? <ButtonLoading className="bg-orange"/> : sizeSelected !== "All" && couriersSelected.length !== 0 && paymentMethodsSelected.length !== 0 && price > 0 && sellerRefs ? 
                            
                            <Button 
                                variant="orange" 
                                className="font-bold"
                                onClick={() => insertBid()}
                                >
                                Confirm
                            </Button> 
                            
                            :  
                            
                            <Button variant="orange" disabled className="font-bold">
                                Confirm
                            </Button>}
                            <div className="flex justify-center">
                                {error && <p>There was an error please try again.</p>}
                            </div>
                            
                        </div>
                        
                       
                    </div>

                    
                </div>
            </div>

            <div className="mt-[300px]">
                <Footer />

            </div>
        </div>
        )
    }
  
  export default Buy
