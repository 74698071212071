import { useContext } from "react"
import { Button } from "../../ui/button"
import AuthContext from "src/context/AuthContext"
import { NavLink } from "react-router-dom"
import ProfileNavigationElement from "./ProfileNavigationElement"
import { LayoutDashboard } from "lucide-react"
import { User } from "lucide-react"
import { ShoppingCart } from "lucide-react"
import { LogOut } from "lucide-react"
import { Euro } from "lucide-react"
import { ArrowBigUp } from "lucide-react"
import { Store } from "lucide-react"


const ProfileNavigation = () => {
    let {logoutUser} = useContext(AuthContext)

    return (
        <div className="hidden sm:flex flex-col text-white px-5 gap-2 pt-24 border-r h-full">
            
            <ProfileNavigationElement link={"/dashboard"} title={"Dashboard"} icon={<LayoutDashboard />}/>
            <ProfileNavigationElement link={"/profile"} title={"Profile"} icon={<User />}/>
            <ProfileNavigationElement link={"/buying/active"} title={"Bids"} icon={<ArrowBigUp />} />
            <ProfileNavigationElement link={"/buying/history"} title={"Purchases"} icon={<ShoppingCart />} />
            <ProfileNavigationElement link={"/selling/active"} title={"Asks"} icon={<Store />}/>
            <ProfileNavigationElement link={"/selling/history"} title={"Sales"} icon={<Euro />}/>




            <Button variant="ghost" onClick={logoutUser} className="flex justify-start px-3 py-2">
                <div className="font-bold flex gap-2 ">
                    <LogOut />
                    <p className="hidden md:flex">log out</p>
                </div>
                
            </Button>
        </div>
        )
    }

  export default ProfileNavigation