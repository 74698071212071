import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import {useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/custom/Forms/LoginForm';
import { Link, useLocation } from 'react-router-dom';
import { NavbarMinimal } from '../components/custom/NavbarMinimal';
import { Loader2 } from "lucide-react";



//prendi id e token da url (pagina che si apre dopo la mail)
//manda request a api/user/confirm_user
//se tutto ok redirect a login altrimenti a register
//nel mentre loading

function ConfirmProfile() {
    let {user, authTokens, server_address} = useContext(AuthContext)
    const navigate = useNavigate();
    const location = useLocation();
    
    const id = new URLSearchParams(location.search).get('id')
    const token = new URLSearchParams(location.search).get('token')
    let [loading, setLoading] = useState(true)
    let [error, setError] = useState(false)

    if(user | !id | !token){
      navigate("/home")      
    }
    
    useEffect(() => {
      confirmUser()
    }, [])

    let confirmUser = async () => {
      let response = await fetch(server_address+'/user/confirm_user', {
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({
              'id': id,
              'token': token
            })
        })
        
        if(response.status === 200){
          setError(false)
          setLoading(false)
          setTimeout(navigate("/login"), 5000);
        }
        else{
          setError(true)
          setLoading(false)
        }
    }

    return (
      <div className="flex flex-col h-dvh">
        <NavbarMinimal />
        <nav>
          {loading ? 
            <Loader2 /> 
            :
            error ? 
            <p>
              There was an error, please try later
            </p> :
            <p>
              You are being automatically redirected to the login page in 5 seconds. If nothing happens <Link className='underline' to={"/login"}> click here </Link>
            </p>
          }  
        </nav>      
        
        
    </div>
    );
  }
  
  export default ConfirmProfile;