// import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "../CustomAccordion";
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "./CustomAccordion";
import { Button } from "../ui/button";
import { Check } from "lucide-react";

export function SelectCouriers({selectedCouriers, couriers, setCouriers}) {

    function deleteCourier(courier){
        setCouriers(selectedCouriers.filter((a) => a === courier ? false : true))
    }
    
    return(

        <Accordion type="single" collapsible>
            <AccordionItem value="item-1">

                <AccordionTrigger selectedSize={selectedCouriers.length === 1 && selectedCouriers[0] === "Default"  && "Default"} 
                classname="text-md">
                        <div className="flex items-center gap-4 text-md">
                            <p> Accepted couriers </p>
                            {selectedCouriers.length !== 0 && <Check />}
                        </div>
                       
                    </AccordionTrigger>
                <AccordionContent>
                    <div className="flex flex-col gap-2">
                        {couriers && couriers.map((courier, index) => (
                            <div className="flex items-center gap-3">
                                {selectedCouriers.includes(courier) ? 
                                <button 
                                    key={index}
                                    className="w-[16px] h-[16px] bg-orange rounded-full" 
                                    onClick={() => {deleteCourier(courier)}}
                                />
                                : 
                                <button 
                                    className="w-[16px] h-[16px] bg-background border border-white rounded-full"
                                    onClick={() => {
                                        setCouriers([
                                            ...selectedCouriers,
                                            courier
                                        ])}} 
                                />}

                                <p>{courier}</p>
                            </div>
                        ))}
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>

    )
}