import React from "react";
import { format } from "date-fns";


const CustomTooltip = props => {
  const { active, payload } = props;
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className="text-black bg-white flex flex-col leading-tight py-[2px] px-[4px] border border-black min-w-[120px]">
        <p className="font-bold">
          {currData ? currData.val : " -- "} €
        </p>
        <p className="text-[13px]">
          {currData ? format(new Date(currData.date), "dd MMMM yyyy") : " -- "}
        </p>

      </div>
    );
  }

  return null;
};

export default CustomTooltip;
