/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/1ADs2FRNaQg
 */
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "../../ui/card"
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ButtonLoading } from "../ButtonLoading";
import FormAlert from "../FormAlert";
import email_valid from "../../../utils/email_valid";

export function LoginForm() {
  let {loginUser, server_address} = useContext(AuthContext)
  let [formValid, setformValid] = useState(true)
  let [loading, setLoading] = useState(false)
  let [userVerified, setUserVerified] = useState(true)
  let [userNotFound, setUserNotFound] = useState(false)
  let [forgotPassword, setForgotPassword] = useState(false)
  let [emailValid, setEmailValid] = useState(true)
  let [resetSuccessful, setResetSuccessful] = useState(false)
  let [emailNotFound, setEmailNotFound] = useState(false)
  let [emailAlreadySent, setEmailAlreadySent] = useState(false)
  let [tryLater, setTryLater] = useState(false)

  const handleSubmit = () =>{ 
    let email = document.getElementById("email").value
    let password = document.getElementById("password").value
    
    if (email !== "" && password !== "")
    {
      setformValid(true)
      setLoading(true)
      let response = loginUser(email, password)
      response.then((response) => {
        // if(response != 200){
        //   setLoading(false)
        //   setformValid(false)
        // }
      if(response === 403){
        setLoading(false)
        setformValid(true)
        setUserVerified(false)
      }

      if(response === 404){
        setLoading(false)
        setformValid(true)
        setUserNotFound(true)
      }       

      })
    }

    else{
      setformValid(false)
      setUserVerified(true)
      setUserNotFound(false)
    }
  }

  const resetPassword = async () =>{ 
    let email = document.getElementById("email").value
    
    if (email !== "" && email_valid(email))
    {
      setEmailValid(true)
      setLoading(true)
      
      let response = await fetch(server_address+'/user/reset_password', {
        method:'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
          'email': email
        })
      })

      if (response.status === 200)
      {
        setResetSuccessful(true)
        
        setLoading(false)
        setEmailNotFound(false)
        setEmailAlreadySent(false)
        setTryLater(false)
        setEmailValid(true)
      }

      else if (response.status === 404)
      {
        setEmailNotFound(true)
        
        setResetSuccessful(false)
        setLoading(false)
        setEmailAlreadySent(false)
        setTryLater(false)
        setEmailValid(true)
      }

      else if (response.status === 403)
      {
        setEmailAlreadySent(true)
        
        setResetSuccessful(false)
        setLoading(false)
        setEmailNotFound(false)
        setTryLater(false)
        setEmailValid(true)
      }
      
      else if (response.status === 500)
      {
        setTryLater(true)

        setResetSuccessful(false)
        setLoading(false)
        setEmailAlreadySent(false)
        setEmailValid(true)
        setEmailNotFound(false)
      }
      
      else if (response.status === 400)
      {
        setEmailValid(false)

        setResetSuccessful(false)
        setLoading(false)
        setEmailAlreadySent(false)
        setEmailNotFound(false)
        setTryLater(false)
      }
    }
    
    else{
      setEmailValid(false)
      setLoading(false)
    }
  }


  if(resetSuccessful){
    return(
     <div className="p-6 mx-auto">
        <h1 className="font-bold text-lg">
          Perfect!
        </h1>
        <p>
          we sent you an email, please click on the link to reset your password
        </p>
      </div>
    )
  }

  else if (!forgotPassword) {
    return(
      <div className="w-[100%]">
        <div className="mx-auto w-[85%] max-w-sm ">
          {!formValid && <FormAlert error={"Please fill in all the fields."} />}
          {!userVerified && <FormAlert error={"Confirm your profile before loggin in."} />}
          {userNotFound && <FormAlert error={"Incorrect email or password."} />}
        </div>
        

        <Card className="mx-auto w-[85%] max-w-sm ">
          <CardHeader className="space-y-1">
            <CardTitle className="text-md font-bold">Login</CardTitle>
            <CardDescription className="">Enter your email and password to login to your account</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 w-full">
              <div className="space-y-2">
                <Label htmlFor="email" className="">Email</Label>
                <Input id="email"  />
              </div>
              <div className="space-y-2">
                <div className="flex items-center">
                  <Label htmlFor="password" className="">Password</Label>

                  <Button variant="link" onClick={() => {setForgotPassword(true)}} className="ml-auto inline-block text-[14px] underline font-normal p-0 h-[20px] ">
                    Forgot your password?
                  </Button>

                </div>
                <Input id="password" required type="password" />
              </div>

              {/* {loading ? <ButtonLoading/> : <Button variant="orange" className="w-full" type="submit" onClick={handleSubmit}> Login </Button>} */}
              {loading ? <ButtonLoading className="w-full"/> : <Button className="w-full" type="submit" onClick={handleSubmit}> Login </Button>}
              
            
            </div>
            <div className="mt-4 text-center text-[14px]">
              Don't have an account?&nbsp;
              <Link className="underline" to={"/register"}>
                Sign up
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }



  else{
    return(
      <div className="w-[100%]">
        <div className="mx-auto w-[85%] max-w-sm ">

        
        {!emailValid && <FormAlert error={"Email not valid."} />}
        {emailNotFound && <FormAlert error={"No user found with the email provided."} />}
        {emailAlreadySent && <FormAlert error={"Email already sent, please check your inbox."} />}
        {tryLater && <FormAlert error={"There was an error, Please try again later."} />}
        <Card className="">
            <CardHeader className="space-y-1">
              <CardTitle className="text-md font-bold">Reset your password</CardTitle>
              <CardDescription >Enter your email to get a link to reset your password</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email"  />
                </div>
                <div className="w-full">
                {loading ? <ButtonLoading className="w-full"/> : <Button className="w-full" type="submit" onClick={resetPassword}> Send link </Button>}
                </div>
              
              </div>
            </CardContent>
          </Card>
          </div>
        </div>
    )
  }
}
