import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "src/components/custom/CustomAccordion";
import { Button } from "src/components/ui/button";
import { Check } from "lucide-react";

export function EditSize({selectedSize, sizes, selectSize}) {
    return(
        <>
        <Accordion type="single" collapsible className="">
            <AccordionItem value="item-1">
                    <AccordionTrigger selectedSize={selectedSize === "All" || !sizes.includes(selectedSize) ? "All" : "EU " + selectedSize}>
                    
                    <div className="flex items-center gap-4 text-sm">
                        <p> Size </p>
                        {selectedSize !== "All" && <Check size={16}/>}
                    </div>
                        
                    </AccordionTrigger>
                <AccordionContent>
                <div className="grid grid-cols-3 gap-4 p-4 ">
                    {selectedSize === "All" ? <Button 
                                                variant="orange" 
                                                className="col-span-3 " 
                                                onClick={() => (selectSize("All"))}
                                                >All
                                            </Button> : <Button
                                                variant="background" 
                                                className="col-span-3 " 
                                                onClick={() => (selectSize("All"))}
                                                > <span className="text-white">All</span>
                                            </Button>}
                    {sizes && sizes.map((size, index) => (
                        selectedSize === size ? <Button 
                        variant="orange" 
                        onClick={() => (selectSize(size))}
                        > EU {size}
                            </Button> : 
                            <Button
                            variant="background"  
                        onClick={() => (selectSize(size))}
                        ><span className="text-white"> EU {size} </span>
                    </Button>
                    ))}
                </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
        </>
    )
}