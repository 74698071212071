import React, { useState } from 'react'

import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import {default as logoFullB} from "../../images/FD-logofullB.svg"
import {default as logoShortB} from "../../images/FD-logoshortB.svg"
import Container from './PageContainer';
import { Button } from '../ui/button';
import { Search } from 'lucide-react';


export const Navbar = ({defaultCollapsed}) => {
    let [isCollapse, setIsCollapse] = useState(() => defaultCollapsed == true | defaultCollapsed == false ? defaultCollapsed : true)

    return (
      <>
      <div className="border-b bg-white ">

      <Container >
      
        <header className="flex flex-col gap-3 md:hidden bg-white py-3">
          <div className="flex justify-between items-center">
            <div >
              <Link to={"/home"}>
                <img src={logoShortB} alt="Logo" className="h-[30px] w-auto"/>
              </Link>
            </div>
            
            <div className="flex gap-3 items-center text-black font-extrabold text-sm" >
              <Link to={'/help'} replace>
                  Help
              </Link>
              <Link className="block sm:hidden" to={'/account'} replace>
                  My account
              </Link>
              <Link className="hidden sm:block" to={'/dashboard'} replace>
                  My account
              </Link>
              {<Button className="px-0 py-0" variant="icon" onClick={() => (setIsCollapse(!isCollapse))}>
                <Search />
              </Button>}
            </div>

          </div>
        
          {!isCollapse && <SearchBar className=""/>}
          
        </header>

        <header className="hidden md:flex justify-between flex-row  items-center gap-5 py-5">
          <div >
            <Link to={"/home"}>
              <img src={logoShortB} alt="Logo" className="h-[40px] w-auto lg:hidden"/>
              <img src={logoFullB} alt="Logo" className="hidden lg:block h-[40px] text-black"/>
            </Link>
          </div>
          <div className="w-[60%] max-w-[840px]">
            <SearchBar className=""/>
          </div>
          
          
          <div className="flex gap-6 items-center text-black font-extrabold text-sm" >
            <Link to={'/help'} replace>
                <p>Help</p>
            </Link>
            <Link to={'/dashboard'} replace className="min-w-[93px]">
                My account
            </Link>
          </div>        

        </header>
        </Container>
        </div>
      </>
    );
}

