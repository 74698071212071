import { Navbar } from "../components/custom/Navbar";
import Footer from "../components/custom/Footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Textarea } from "../components/ui/textarea";


function User() {
  let {server_address, authTokens} = useContext(AuthContext)
  const params = useParams()
  const username = params.username
  let [user, setUser] = useState()

  useEffect(() => {
    getUser()
  }, [])

  let getUser = async () => {
    let response = await fetch(server_address+'/user/username/' + username, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
          let data = await response.json()
          setUser(data)
        } 
  }


    return (
      <div className=" bg-background">
        <div className="sticky top-0 z-10">
          <Navbar />
        </div>
        {user && 
        <div className="p-[15px] sm:p-11 text-white">
          <h1 className="font-bold test-md sm:text-lg">
          {username}
          </h1>
          <ol className="mt-4 flex flex-col gap-3">
            <li>
              <span className="font-bold">{user.seller_refs}</span> seller refs
            </li>
            <li>
            <span className="font-bold">{user.buyer_refs}</span> buyer refs
            </li>
            <li>
              from {user.nation.name}
            </li>
            <li>
              <Textarea disabled value={user.description}>
                
              </Textarea>
            </li>
          </ol>

        </div>
        }
        

        <div className="mt-[500px]">

        </div>
        <Footer />
        

        
  
      </div>
    );
  }
  
  export default User;