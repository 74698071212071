import { Navbar } from "../components/custom/Navbar"
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import ShoeCard from "../components/custom/ShoeCard";
import Container from "../components/custom/PageContainer";
import Footer from "../components/custom/Footer";

function Search(){
    let [searchParams, setSearchParams] = useSearchParams();
    let [results, setResults] = useState([]);
    let {authTokens, server_address} = useContext(AuthContext)

    const query = searchParams.get('q');

    useEffect(() => {
        getShoes()
    }, [query])

    let getShoes = async () => {
        
        let results = await fetch(server_address+'/shoes/search?' + new URLSearchParams({
            q: query,
            page: 1,
            limit: 32,
            }), {
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + String(authTokens?.access)
                }
            })

        let data = await results.json()
        setResults(data.shoes)
    }

    return(
        <div className="bg-white h-[100%] text-black">
            <div className="sticky top-0 z-10">
                <Navbar defaultCollapsed={false}/>
            </div>
            <div className="min-h-dvh">
                <Container>
                    {results.length > 0 ? 
                        <div className="max-w-[1450px] m-auto flex flex-wrap bg-white gap-x-5 gap-y-6 my-6">
                        {results && results.map((shoe, index) => (
                            <ShoeCard shoe={shoe}/>
                        ))}
                        </div> 
                    : 
                        <div className="flex flex-col my-3">
                            <h1 className="text-md">
                                No results found
                            </h1>
                            <p>
                                If you think there is a shoe missing, let us know via discord! <a href="https://discord.gg/aBAu8wCGrq" className="font-bold underline">Join here</a>
                            </p>
                        </div>
                    }
                </Container>
                
            </div>
            
            <Footer />
        </div>
        
    )
}

export default Search
