import * as React from "react"
 
import { ScrollArea } from "src/components/ui/scroll-area"
import { Separator } from "src/components/ui/separator"
 

export function ScrollableList({list}) {
// console.log(list)
  return (
    <ScrollArea className="h-72 w-[100%]">
      <div className="p-4">
        {list.map((courier) => (
          <>
            <div key={courier} className="text-sm">
              {courier.name}
            </div>
            <Separator className="my-2" />
          </>
        ))}
      </div>
    </ScrollArea>
  )
}