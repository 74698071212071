import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { AlertCircle } from "lucide-react"

function FormAlert({error}) {
    
    return (
        <Alert variant="destructive" className={'mb-3'}>
            <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  {error}
                </AlertDescription>
          </Alert>
    );
  }
  
  export default FormAlert;