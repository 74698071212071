import { NavLink } from "react-router-dom"
import { ChevronRight } from "lucide-react"

const AccountNavigationElement = ({link, title, icon, description}) => {
    

    return (

        // <NavLink to={link} className="aria-[current=page]:bg-gray/40 hover:bg-gray/20">
        <NavLink to={link} className="aria-[current=page]:bg-orange aria-[current=page]:text-black hover:bg-gray/20 rounded-md">
            <div className="flex gap-1 px-[15px] py-2 justify-between items-center">
                <div className="flex gap-4 items-center">
                    {icon}
                    <div className="flex flex-col gap-1">
                        <p className="font-bold">{title}</p>
                        <p className="text-xs">Nome, email, password, numero di scarpe</p>
                    </div>
                    
                </div>
                <ChevronRight />
            </div>
            
        </NavLink>

        )
    }

  export default AccountNavigationElement