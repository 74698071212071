import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Navigate, Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {default as logoFullW} from "../images/FD-logofullW.svg"


function LandingPage() {
  let {user, server_address} = useContext(AuthContext)
  let navigate = useNavigate()
  let [registeredUsers, setRegisteredUsers] = useState()
  let [activeAsks, setActiveAsks] = useState()
  let [activeBids, setActiveBids] = useState()
  let [totalSales, setTotalSales] = useState()
  let [totalVolume, setTotalVolume] = useState()

  useEffect(() => {
    getStats()
  }, [])

  let getStats = async () => {
    let response = await fetch(server_address+"/stats?", {
      method:'GET',
      headers:{
          'Content-Type':'application/json',
      }
    })

  
    if(response.status === 200)
    {
        let data = await response.json()
        setRegisteredUsers(data.registered_users)
        setActiveAsks(data.active_asks)
        setActiveBids(data.active_bids)
        setTotalSales(data.total_sales)
        setTotalVolume(data.total_generated)

    } 
  }

  if (user){
    return <Navigate to="/home" replace />;
  }

  else{
    return (
      <div className="w-full">
        <header className="flex justify-center h-[60px] items-center">
          <div className="absolute right-5 text-white top-3 font-semibold text-sm" >
            <Link className="underline " to={"/login"}>
              Log in
            </Link>
          </div>
        </header>
      
          <div className="flex flex-col items-center absolute top-[45%] left-1/2 -translate-y-1/2 -translate-x-1/2 w-full gap-[0px]">
            <img src={logoFullW} alt="My Happy SVG" className="px-[5%] max-w-[1000px] py-[20px] lg:py-[2%] "/>
            <Button variant="orange" className="w-[35%] max-w-[200px] max-h-[35px]" size={"lg"} onClick={() => {navigate("/register")}}>
              Register now
            </Button>
          </div>

          <div className="absolute leading-[1.2] top-[70%] left-1/2 -translate-y-2/8 -translate-x-1/2 grid grid-cols-2 gap-y-6 px-8 md:top-[80%] md:flex md:justify-around w-full    ">
            <div className="flex flex-col items-center ">
              <p className="text-orange font-bold text-md md:text-lg">{registeredUsers}</p>
              <p className="text-xs sm:text-sm">registered users</p>
            </div>  
            <div className="flex flex-col items-center">
              <p className="text-orange font-bold text-md md:text-lg">{activeAsks}</p>
              <p className="text-xs sm:text-sm">active asks</p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-orange font-bold text-md md:text-lg">{activeBids}</p>
              <p className="text-xs sm:text-sm">active bids</p>
            </div>
            <div className="flex flex-col items-center">
              <p className="text-orange  font-bold text-md md:text-lg">€{totalVolume}</p>
              <p className="text-xs sm:text-sm">generated</p>
            </div>
          </div>
      </div>
        
    );
  }

  
  }
  
  export default LandingPage;