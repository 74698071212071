import { useState } from "react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "src/components/ui/alert-dialog"

import { Button } from "src/components/ui/button"
import AuthContext from "src/context/AuthContext"
import { useContext } from "react"

  export function DeleteOffer({id, refresh, setRefresh}) {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [open, setOpen] = useState()

    let deleteOffer = async() => {
        let response = await fetch(server_address+'/marketplace/delete?' + new URLSearchParams({
            id: id,
        }), {
            method:'DELETE',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })
        
        if (response.status == 200)
        {
            setOpen(false)
            setRefresh(!refresh)    
        }
        
    } 

    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
        <Button variant="destructive" className="font-bold sm:w-[100px]">
                      Delete
                    </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-md">Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
            This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={() => deleteOffer()}>Confirm</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }