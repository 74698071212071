import { Link } from "react-router-dom"
import { Navbar } from "../components/custom/Navbar"
import Footer from "../components/custom/Footer"

const ErrorPage = () => {


    return (
        <div>
            <div className="sticky top-0 xl:col-span-2 z-10">
                <Navbar />
            </div>
            
            <div className=" p-[15px] sm:p-11 h-dvh">
                <h1 className="text-md sm:text-lg font-bold">
                    Error 404
                </h1>
                <p>
                    page not found
                </p>
               
            </div>
           
            <div className="">
                <Footer />
            </div>
        </div>
        )
    }
  
  export default ErrorPage