import { NavLink } from "react-router-dom"

const ProfileNavigationElement = ({link, title, icon}) => {
    

    return (

        // <NavLink to={link} className="aria-[current=page]:bg-gray/40 hover:bg-gray/20">
        <NavLink to={link} className="aria-[current=page]:bg-orange aria-[current=page]:text-black hover:bg-gray/20 rounded-md">
            <div className="flex gap-1">
                <div className="flex py-2 px-3 gap-2">
                    {icon}
                    <p className="font-bold hidden md:flex">{title}</p>
                </div>
            </div>
            
        </NavLink>

        )
    }

  export default ProfileNavigationElement