import { useState } from "react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "src/components/ui/alert-dialog"

import { Button } from "src/components/ui/button"
import AuthContext from "src/context/AuthContext"
import { useContext } from "react"
import { ButtonLoading } from "../ButtonLoading"

export function AcceptOffer({offer, refresh, setRefresh}) {
let {authTokens, logoutUser, server_address, user} = useContext(AuthContext)
let [open, setOpen] = useState()
let [loading, setLoading] = useState(false)

let acceptOffer = async() => {
    setLoading(true)
    let response = await fetch(server_address+"/marketplace/accept", {
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            'Authorization': 'Bearer ' + String(authTokens?.access)
        },
        body:JSON.stringify({
            'id': offer.id
        })
    })
    
    
    if (response.status == 200)
    {
        setLoading(false)
        setOpen(false)
        setRefresh(!refresh)    
    }
    
} 

return (
    <AlertDialog open={open} onOpenChange={setOpen}>
    <AlertDialogTrigger asChild>
    {offer.creator === user.username ? 
            <Button variant="orange" className="font-bold w-[200px]" disabled>
                {offer.deals_type === "Ask" ? 
                    <p>
                        Buy now
                    </p> :
                    <p>
                        Sell now
                    </p>
                }
            </Button> :
            <Button variant="orange" className="font-bold w-[200px]">
                {offer.deals_type === "Ask" ? 
                    <p>
                        Buy now
                    </p> :
                    <p>
                        Sell now
                    </p>
                }
            </Button>
        }
    </AlertDialogTrigger>
    <AlertDialogContent>
        <AlertDialogHeader>
        <AlertDialogTitle className="text-md">Accept this offer</AlertDialogTitle>
        <AlertDialogDescription>
        This action cannot be undone, make sure to have read the rules.
        </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
        {!loading && <AlertDialogCancel>Cancel</AlertDialogCancel>}
        {loading ?
            <ButtonLoading /> 
                :
            <Button onClick={() => acceptOffer()}>Confirm</Button>        
        }
        </AlertDialogFooter>
    </AlertDialogContent>
    </AlertDialog>
)
}