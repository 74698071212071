import ShoeStatsCard from "./ShoeStatsCard"

export default function ShoeStats({salesNumber, volatility, averagePrice, minValue, maxValue, lastSalePrice, averageSellingTimeDays}){
    
    return(
        <div className="grid grid-cols-2 gap-[15px] sm:gap-6">
            <ShoeStatsCard header={salesNumber} description={"Number of sales"} />
            <ShoeStatsCard header={volatility + "%"}  description={"Volatility"} />
            <ShoeStatsCard header={averageSellingTimeDays} description={"Average selling days"} />
            <ShoeStatsCard header={"€" + averagePrice } description={"Average selling price"} />
            <ShoeStatsCard header={"€" + lastSalePrice} description={"Last sale price"} />
            <ShoeStatsCard header={"€" + minValue + " - " + "€" + maxValue } description={"Trade range"} />
            

        </div>
    )
}