import { Link } from "react-router-dom"
import { Instagram } from 'lucide-react';
import { Copyright } from 'lucide-react';

function Footer(){
    return(
        <div className="w-100%  h-[400px] bg-background border-t border-white text-white p-[15px] sm:p-11">
            <div className="flex w-full justify-between">
                <div className="grid grid-cols-1 gap-7 sm:grid-cols-3 w-full">
                    <div className="flex flex-col gap-4">
                        <p className="text-md font-bold">
                            Shoes
                        </p>
                        <div className="flex flex-col gap-2">
                            <Link to={"/search?q=nike"}>
                                Nike
                            </Link>
                            <Link to={"/search?q=adidas"}>
                                Adidas
                            </Link>
                            <Link to={"/search?q=jordan"}>
                                Air Jordan
                            </Link>
                            <Link to={"/search?q=ugg"}>
                                Ugg
                            </Link>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        <p className="text-md font-bold">
                            Rules
                        </p>
                        <div className="flex flex-col gap-2">
                            <Link to={"/rules"}>
                                Marketplace rules
                            </Link>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        <p className="text-md font-bold">
                            Contact us
                        </p>
                        <div className="flex flex-col gap-2">
                            <Link to={"/help"}>
                                Email
                            </Link>
                            <Link to={"https://discord.gg/aBAu8wCGrq"}>
                                Discord
                            </Link>
                        </div>
                    </div>


                </div>
                <a href="https://www.instagram.com/finedeal.eu/" className="pt-[6px]">
                    <Instagram />
                </a>
                
            </div>
            <div className="flex pb-[30px] flex-col mt-14 gap-4 sm:flex-row sm:gap-[80px] sm:mt-[90px] sm:pb-0 text-xs">
                <Link to={"/terms"}>
                    Terms of service
                </Link>
                <Link  to={"/privacy"}>
                    Privacy policy
                </Link>
                <div className=" flex gap-1 items-center">
                    <Copyright size={16}/>
                    2024 Fine Deal. All Rights Reserved
                </div>
            </div>
            
        </div>
    )
}

export default Footer