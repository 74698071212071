import { useState, useContext } from "react";
import { useNavigate, createSearchParams} from 'react-router-dom';
import { Input } from "../ui/input";
import { Search } from "lucide-react";
import { useSearchParams } from "react-router-dom";

const SearchBar = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('q');
  const [input, setInput] = useState("");  
  const navigate = useNavigate()
  
  
  const handleChange = (e) => {
    //prendi l'input
    let value = e.target.value
    setInput(value);
  };

  const handeKeyDown = (e) => {
    if (e.key === "Enter")
    { 
     
      let query = input;
      let params = {q: query};
      navigate({
        pathname: '/search',
        search: `?${createSearchParams(params)}`,
      });
    }
  }

  return (
    <div className="flex items-center border border-input h-8 text-black">
        <input 
          type="text" 
          // placeholder={("Type to search..." )}
          placeholder={query ? query : "Search the item you want to buy or sell..."}
          className="ml-[10px] outline-none w-[100%]"
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handeKeyDown(e)}
        />
    </div>
    
    );
}

export default SearchBar