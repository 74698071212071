/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/1ADs2FRNaQg
 */
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "../ui/card"
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useState } from "react";
import { ButtonLoading } from "./ButtonLoading";
import FormAlert from "./FormAlert";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

export function ResetPasswordForm() {
    let [searchParams, setSearchParams] = useSearchParams();
    let {server_address} = useContext(AuthContext)
    const navigate = useNavigate();

    let [formValid, setformValid] = useState(true)
    let [loading, setLoading] = useState(false)
    let [resetSuccessful, setResetSuccessful] = useState(false)
    let [tryLater, setTryLater] = useState(false)
    let [samePasswords, setSamePasswords] = useState(true)
    
    const id = searchParams.get('id');
    const token = searchParams.get('token');

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const resetPassword = async () =>{ 
        let password = document.getElementById("password").value
        let confirm_password = document.getElementById("confirm_password").value

        if (password !== "" && confirm_password !== "" && password === confirm_password)
        {
            setLoading(true)
            setformValid(true)
            setSamePasswords(true)
        
            let response = await fetch(server_address+"/user/reset_password/confirm", {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    'id': id,
                    'token': token, 
                    'password': password,
                    'password_confirmation': confirm_password
                })
            })
            
            if (response.status === 200)
            {
                setResetSuccessful(true)
                await sleep(3000)
                navigate("/login")
            }

            else{
                setLoading(false)
                setTryLater(true)
            }
        }
        
        else if(password === "" || confirm_password === ""){
        setformValid(false)
        setLoading(false)
        }

        else if(password !== confirm_password)
        {
            setSamePasswords(false)
            setformValid(true)
        }
    }

    if (resetSuccessful)
    {
        return(
          <div>
            <h1 className="font-bold text-3xl">
                Your password has been reset!
            </h1>
            <p>
                you are being redirected to the log in. if you are not redirected automatically <Link to={"/login"} className="underline"> Click here </Link>
            </p>
            
          </div>
        )
      
    }

    else{
        return(
            <div className="font-inter">
                {!formValid && <FormAlert error={"Please fill in all the fields."} />}
                {!samePasswords && <FormAlert error={"Passwords do not match."} />}
                {tryLater && <FormAlert error={"There was an error, please try again."} />}

                <Card className="mx-auto max-w-sm">
                <CardHeader className="space-y-1">
                    <CardTitle className="text-2xl font-bold">Reset your password</CardTitle>
                    <CardDescription>Please enter your new password and confirm it</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="password">Password</Label>
                        <Input id="password" required type="password"/>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="confirm_password" >Confirm password</Label>
                        <Input id="confirm_password" required type="password" />
                    </div>
                    

                    {loading ? <ButtonLoading/> : <Button className="w-full" type="submit" onClick={resetPassword}> Reset </Button>}
                    
                    </div>
                </CardContent>
                </Card>
            </div>
            );
        }

}
