import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "../../ui/card"
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { ButtonLoading } from "../ButtonLoading";
import FormAlert from "../FormAlert";
import { Textarea } from "../../ui/textarea";
import { SelectNation } from "../SelectNation";

export function RegisterForm() {
  let {RegisterUser} = useContext(AuthContext)
  let [fullFields, setFullFields] = useState(true)
  let [loading, setLoading] = useState(false)
  let [registerSuccessful, setRegisterSuccessful] = useState(false)
  let [emailTaken, setEmailTaken] = useState(false)
  let [usernameTaken, setUsernameTaken] = useState(false)
  let [passwordMatch, setPasswordMatch] = useState(true)
  let [emailValid, setEmailValid] = useState(true)
  let [usernameValid, setUsernameValid] = useState(true)
  let [next, setNext] = useState(false)
  
  let [nation, setNation] = useState("Italy")
  let [description ,setDescription] = useState("")
  let [username, setUsername] = useState("")
  let [email, setEmail] = useState("")
  let [password, setPassword] = useState("")
  let [confirmPassword, setConfirmPassword] = useState("")

  const handleSubmit = () =>{
 
    
    if (username != "" && email != "" && password != "" && confirmPassword != "" && password == confirmPassword && description != "")
    {
      setFullFields(true)
      setEmailTaken(false)
      setUsernameTaken(false)
      setPasswordMatch(true)
      setLoading(true)
      setEmailValid(true)
      setUsernameValid(true)

      let response = RegisterUser(username, email, password, confirmPassword, description, nation)
      response.then((response) => {
        if (response==200){
          setRegisterSuccessful(true)
        }

        else{
          setLoading(false)
          if (response == "Empty fields"){
            setFullFields(false)

          }
          else if (response == "Password do not match")
          {
            setPasswordMatch(false)
            
          }
          else if (response == "Email already taken")
          {
            setEmailTaken(true)
          }
          else if (response == "Username already taken")
          {
            setUsernameTaken(true)
          }
          else if (response == "Username not valid")
          {
            setUsernameValid(false)
          }
          else if (response == "Email not valid")
          {
            setEmailValid(false)
          }



        }

      })
    }

    else if(username == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(email == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(password == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }
    
    else if(confirmPassword == ""){
      setFullFields(false)   
      
      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(description==""){
      setFullFields(false)

    }

    else if(password != confirmPassword){
      setPasswordMatch(false)

      setFullFields(true)  
      setEmailTaken(false)
      setEmailValid(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }
  }
  
  const handleNext = () => {
    let username = document.getElementById("username").value
    let email = document.getElementById("email").value
    let password = document.getElementById("password").value
    let confirm_password = document.getElementById("confirm_password").value
    
    if (username != "" && email != "" && password != "" && confirm_password != "" && password == confirm_password)
    {
      setFullFields(true)
      setEmailTaken(false)
      setUsernameTaken(false)
      setPasswordMatch(true)
      setEmailValid(true)
      setUsernameValid(true)
      
      setNext(true)
      
      
    }

    else if(username == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(email == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(password == ""){
      setFullFields(false)

      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }
    
    else if(confirm_password == ""){
      setFullFields(false)   
      
      setEmailTaken(false)
      setEmailValid(true)
      setPasswordMatch(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }

    else if(password != confirm_password){
      setPasswordMatch(false)

      setFullFields(true)  
      setEmailTaken(false)
      setEmailValid(true)
      setUsernameTaken(false)
      setEmailValid(true)
    }
  }

  if (!registerSuccessful)
  {
    return (
        <div className="w-[85%] mx-auto max-w-sm ">
          {!fullFields && <FormAlert error={"Please fill in all the fields."} />}
          {emailTaken && <FormAlert error={"Email already taken."} />}
          {usernameTaken && <FormAlert error={"Username already taken."} />}
          {!passwordMatch && <FormAlert error={"Passwords do not match."} />}
          {!usernameValid && <FormAlert error={"Username can only contain . or _"} />}
          {!emailValid && <FormAlert error={"Email not valid."} />}

          <Card className="">
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl font-bold">Register</CardTitle>
                <CardDescription>Enter your information to register a new account</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {next ? 
                    <>
                      <div className="space-y-2">
                        <Label htmlFor="message-2">Contact information</Label>
                          <div className="flex flex-col gap-1">
                              <Textarea 
                                  placeholder="ex: 
ig: @finedeal.eu
discord: @finedeal#0000" 
                                  id="bio"
                                  onChange={(e) => setDescription(e.target.value)}
                              />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="nation">Select your nation</Label>
                        <SelectNation nation={nation} setNation={setNation} className="w-full"/>
                      </div>
                      {
                        loading ? 
                        <ButtonLoading className="w-full"/> : 
                        <Button className="w-full" type="submit" onClick={handleSubmit}> Register </Button>
                      }
                    </>                   
                        :
                    <>
                      <div className="space-y-2">
                        <Label htmlFor="username">Username</Label>
                        <Input 
                          id="username" 
                          required
                          onChange={(e) => setUsername(e.target.value)}
                          />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="email">Email</Label>
                        <Input 
                          id="email" 
                          type="email" 
                          required
                          onChange={(e) => setEmail(e.target.value)}  
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="password">Password</Label>
                        <Input 
                          id="password" 
                          required 
                          type="password" 
                          onChange={(e) => setPassword(e.target.value)}
                          />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="confirm_password">Confirm Password</Label>
                        <Input 
                          id="confirm_password" 
                          required 
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}  
                        />
                      </div>
                      <Button className="w-full" type="submit" onClick={() => handleNext()}> Next </Button>
                    </> 
                    
                  }

                </div>
                <div className="mt-4 text-center text-[14px]">
                  Already got an account?&nbsp;
                  <Link className="underline" to={"/login"}>
                    Log in
                  </Link>
                </div>
              </CardContent>
            </Card>
      </div>
    );
  }
  
  else{
    return(
      <div className="p-6 mx-auto">
        <h1 className="font-bold text-3xl">
          Perfect!
        </h1>
        <p>
          we sent you an email, please click on the link to verify your profile
        </p>
      </div>
    );
  }
  
}