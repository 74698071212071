export default function DashboardSales({name, amount, size}) {
    return (
      <div className="flex justify-between items-center overflow-hidden">
        <div className="flex gap-3 overflow-hidden text-ellipsis whitespace-nowrap  flex-1">
            <p className="">
                    {size}
            </p>
            <p className="">
                    {name}
            </p>
        </div>
        
            <p className="font-bold pl-8">{amount}</p>
      </div>
    );
  }

