import { Navbar } from "../components/custom/Navbar"
import { useContext, useState, useEffect } from "react"
import AuthContext from "../context/AuthContext"
import { Button } from "../components/ui/button"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import UserTablePurchases from "../components/custom/tables/userTables/UserTablePurchases"
import PageTitle from "../components/custom/ProfileNavigation/PageTitle"
import UserTablePendingBids from "../components/custom/tables/userTables/UserTablePendingBids"
import Footer from "../components/custom/Footer"

const BuyingCompleted = () => {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [pending, setPending] = useState()
    let [purchases, setPurchases] = useState()
    let [pageCompleted, setPageCompleted] = useState(1)
    let [pagePending, setPagePending] = useState(1)
    let [totalPending, setTotalPending] = useState()
    let [totalCompleted, setTotalCompleted] = useState()
    let [limitPending, setLimitPending] = useState(10)
    let [limitCompleted, setLimitCompleted] = useState(10)
    let [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getPending()
        getCompleted()
    }, [])
    
    useEffect(() => {
        getPending()
    }, [pagePending, limitPending, refresh])

    useEffect(() => {
        getCompleted()
    }, [pageCompleted, limitCompleted])

    let getCompleted = async () => {
        let completed = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "user",
            deals_type: "Bid",
            status: "completed",
            limit: limitCompleted,
            page: pageCompleted
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        if(completed.status === 200)
        {
            let completedData = await completed.json()
            setPurchases(completedData.marketplace)
            completedData.pagination.total_pages > 0 ? setTotalCompleted(completedData.pagination.total_pages) : setTotalCompleted(1)
        } else if(completed.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getPending = async () => {
        
        let pending = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "user",
            deals_type: "Bid",
            status: "pending",
            limit: limitPending,
            page: pagePending
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

                
        if(pending.status === 200)
        {
            let pendingData = await pending.json()

            setPending(pendingData.marketplace)
            pendingData.pagination.total_pages > 0 ? setTotalPending(pendingData.pagination.total_pages) : setTotalPending(0) 

        } else if(pending.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    return (
        <div >
            <div className="bg-white sticky top-0 z-10">
                <Navbar />
            </div>
            <div className="flex">
                <div className="sticky top-0 z-1 self-start h-dvh">
                    <ProfileNavigation />
                </div>
                <div className="p-[15px] sm:p-6 w-full overflow-auto">
                    <PageTitle title="Pending Purchases" />
                    <div className="mt-3">
                        {pending && <UserTablePendingBids purchases={pending} setPurchases={setPending} page={pagePending} setPage={setPagePending} totalPages={totalPending} limit={limitPending} setLimit={setLimitPending} refresh={refresh} setRefresh={setRefresh}/>}
                    </div>
                    <h1 className="text-md font-bold mt-5"> Purchases history </h1>
                    <div className="mt-3">
                        {purchases && <UserTablePurchases purchases={purchases} setPurchases={setPurchases} page={pageCompleted} setPage={setPageCompleted} totalPages={totalCompleted} limit={limitCompleted} setLimit={setLimitCompleted}/>}
                    </div>    
                </div>
            </div>
            <div className="mt-[500px]">
                <Footer></Footer>
            </div>
        </div>
        )
    }

  export default BuyingCompleted