import React from 'react'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RegisterForm } from '../components/custom/Forms/RegisterForm';
import { Link } from 'react-router-dom';
import { Navbar } from '../components/custom/Navbar';
import { NavbarMinimal } from '../components/custom/NavbarMinimal';

function Register() {
    let {user} = useContext(AuthContext)
    const navigate = useNavigate();

    if(user){
      navigate("/home")      
    }
    
    return (
      <>
        {/* <NavbarMinimal />
        
        <div className='flex w-full items-center absolute top-[50%] left-1/2 -translate-y-1/2 -translate-x-1/2'>
          <RegisterForm />
        </div> */}

        <div className="flex flex-col h-dvh">
          <NavbarMinimal />      
          <div className="flex flex-col h-full justify-center">
            <RegisterForm />
          </div>

        </div>
      </>


    );
  }
  
  export default Register;