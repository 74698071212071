export default function ShoeStatsCard({header, description}){
    return <div className="flex flex-col border border-white rounded-md px-3 py-[6px] text-white ">
        <p className="font-bold text-sm sm:text-md">
            {header}
        </p>
        {/* <p className="-mt-[5px] "> */}
        <p className="text-xs sm:text-sm">
            {description}
        </p>
    </div>
}