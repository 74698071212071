import { useContext } from "react"
import { Button } from "../../ui/button"
import AuthContext from "src/context/AuthContext"
import { LayoutDashboard } from "lucide-react"
import { User } from "lucide-react"
import { ShoppingCart } from "lucide-react"
import { LogOut } from "lucide-react"
import { Euro } from "lucide-react"
import { ArrowBigUp } from "lucide-react"
import { Store } from "lucide-react"
import AccountNavigationElement from "./AccountNavigationElement"


const AccountNavigation = () => {
    let {logoutUser} = useContext(AuthContext)

    return (
        <div className="flex flex-col h-full justify-around text-white gap-4">
            
            <AccountNavigationElement link={"/dashboard"} title={"Dashboard"} icon={<LayoutDashboard />}/>
            <AccountNavigationElement link={"/profile"} title={"Profile"} icon={<User />}/>
            <AccountNavigationElement link={"/buying/active"} title={"Bids"} icon={<ArrowBigUp />} />
            <AccountNavigationElement link={"/buying/history"} title={"Purchases"} icon={<ShoppingCart />} />
            <AccountNavigationElement link={"/selling/active"} title={"Asks"} icon={<Store />}/>
            <AccountNavigationElement link={"/selling/history"} title={"Sales"} icon={<Euro />}/>

            <Button variant="ghost" onClick={logoutUser} className="flex gap-4 justify-start">
                    <LogOut />
                    <p className="font-bold">Logout</p> 
                        
            </Button>

            <div className="flex gap-1 items-center">
                <div className="flex items-center">
                    
                    
                             
                </div>
            </div>
        </div>
        )
    }

  export default AccountNavigation