import { Link } from "react-router-dom";

function ShoeCard({shoe}) {
    
    if (shoe){
        let redirectLink = "/shoes/" + shoe.name
        return (
            <Link to={redirectLink}>
                <div className="flex flex-col w-[160px]">
                    <div className=" bg-white max-h-[99px]">
                        <img 
                            // src={shoe.image_small}
                            src={"https://www.finedeal.eu/images/small/"+shoe.name+".jpg"}
                            width="140px"
                            height="75px"
                            alt="shoe photo"
                            className="mx-auto my-3"
                        >
                        </img>
                    </div>
                    {/* <div className="flex flex-col p-2 bg-white">
                        <div>
                            <p className="text-black font-bold text-sm leading-tight line-clamp-2">{shoe.title}</p>
                        </div>
                        <div className="mt-1">
                            <p className="text-black text-sm">Last sale</p>
                            <p className="text-black font-bold text-md">{shoe.last_sale ? shoe.last_sale : "-"} €</p>
                        </div>
                    </div> */}
                    <div className="flex flex-col p-2 bg-white">
                        <div>
                            <p className="text-black text-sm leading-tight line-clamp-2">{shoe.title}</p>
                        </div>
                        <div className="mt-1">
                            <p className="text-black text-xs">Last sale</p>
                            <p className="text-black font-bold text-md">{shoe.last_sale ? shoe.last_sale : "-"} €</p>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
    
  }
  
  export default ShoeCard;