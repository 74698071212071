import { Navbar } from "../components/custom/Navbar"
import { useParams } from "react-router-dom";
import { SelectSize } from "../components/custom/SelectSize";
import { useState } from "react";
import { Button } from "../components/ui/button";
import PriceChart from "../components/custom/Sku/Charts/PriceChart/PriceChart";
import { Separator } from "../components/ui/separator";
import { SelectTimeframe } from "../components/custom/Sku/SelectTimeframe";
import ShoeStats from "../components/custom/Sku/ShoeStats";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import SkuTableBids from "../components/custom/tables/skuTables/SkuTableBids";
import SkuTableAsks from "../components/custom/tables/skuTables/SkuTableAsks";
import Footer from "../components/custom/Footer";
import SkuTableSales from "../components/custom/tables/skuTables/SkuTableSales";

// dati da mostrare:
// titolo, sku, categoria, 
// per ogni timeframe:
// grafico vendite
// n vendite, volatilità, prezzo medio, valore min, valore max, valore ultima vendita

const ProductPage = () => {
    const params = useParams();
    let {authTokens, logoutUser, server_address, user} = useContext(AuthContext)
    const name = params.name
    let [sizeSelected, setSizeSelected] = useState("All")
    let [timeframe, setTimeframe] = useState("1M")
    let navigate = useNavigate()
    
    let [shoe, setShoe] = useState()
    let [stats, setStats] = useState()
    let [sizes, setSizes] = useState()
    let [bids, setBids] = useState()
    let [asks, setAsks] = useState()
    let [sales, setSales] = useState()
    let [graphSales, setGraphSales] = useState()
    let [startingDate, setStartingDate] = useState()
    let [endingDate, setEndingDate] = useState()

    let [pageAsks, setPageAsks] = useState(1)
    let [pageBids, setPageBids] = useState(1)
    let [pageSales, setPageSales] = useState(1)

    let [limitAsks, setLimitAsks] = useState(10)
    let [limitBids, setLimitBids] = useState(10)
    let [limitSales, setLimitSales] = useState(10)
    
    let [totalAsks, setTotalAsks] = useState()
    let [totalBids, setTotalBids] = useState()
    let [totalSales, setTotalSales] = useState()

    let [refreshBids, setRefreshBids] = useState(false)
    let [refreshAsks, setRefreshAsks] = useState(false)


    useEffect(() => {
        getShoe()
        gedBids()
        getAsks()
        getSales()
        getGraphSales()
        getStats()
    }, [])

    useEffect(() => {
        if (shoe){
            getSizes()

        }
    }, [shoe])

    useEffect(() => {
        getGraphSales()
        getStats()
    }, [timeframe])

    useEffect(() => {
        gedBids()
        getAsks()
        getSales()
        getGraphSales()
    }, [sizeSelected])

    useEffect(() => {
        getAsks()
    }, [pageAsks, limitAsks, refreshAsks])

    useEffect(() => {
        gedBids()
    }, [pageBids, limitBids, refreshBids])
    
    useEffect(() => {
        getSales()
    }, [pageSales, limitSales, refreshAsks, refreshBids])

    let getShoe = async () => {
        
        let response = await fetch(server_address+'/shoes?' + new URLSearchParams({
            name: name,
            page: 1,
            limit: 1,
            brand: '',
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setShoe(data)
        }
        else if(response.status === 404)
        {
            navigate("/notfound")
        } 
        else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getSizes = async () => {
        
        let response = await fetch(server_address+'/sizes?' + new URLSearchParams({
            name: name,
            category: "",
            brand: "",
            
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let sizesArray = []
            data.map((size) => {
                sizesArray.push(size.size)
            })
            setSizes(sizesArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let gedBids = async () => {
        let bids = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "name",
            name: name,
            size: sizeSelected,
            tf: timeframe,
            page: pageBids,
            limit: limitBids,
            deals_type: "Bid",
            status: "active"
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(bids.status === 200)
        {
            let data = await bids.json()
            setBids(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalBids(data.pagination.total_pages) : setTotalBids(1)

        } else if(bids.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getAsks = async () => {
        let asks = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "name",
            name: name,
            size: sizeSelected,
            tf: timeframe,
            page: pageAsks,
            limit: limitAsks,
            deals_type: "Ask",
            status: "active"
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(asks.status === 200)
        {
            let data = await asks.json()
            setAsks(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalAsks(data.pagination.total_pages) : setTotalAsks(1)

        } else if(asks.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getSales = async () => {
        let sales = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "name",
            name: name,
            size: sizeSelected,
            tf: timeframe,
            page: pageSales,
            limit: limitSales,
            deals_type: "",
            status: "completed"
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(sales.status === 200)
        {
            let data = await sales.json()
            setSales(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalSales(data.pagination.total_pages) : setTotalSales(1)

        } else if(sales.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getGraphSales = async () => {
        let graphSales = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "name",
            name: name,
            size: sizeSelected,
            tf: timeframe,
            page: 1,
            limit: 10,
            deals_type: "",
            status: "graph_sales"
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        

        if(graphSales.status === 200)
        {
            let data = await graphSales.json()
            let graph_data = []

            if (data.sales){
                data.sales.map((a) => {
        
                    let b = {
                        date: new Date(a.acceptation_date).getTime(),
                        val: a.price
                    }
        
                    graph_data.push(b)
                    })
            } 
            
            // let sorted_graph_data = graph_data.sort((a, b) => a.date - b.date);
            setGraphSales(graph_data)
            setStartingDate(new Date(data.starting_date))
            setEndingDate(new Date(data.ending_date))

        } else if(sales.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }


    let getStats = async () => {
        let bids = await fetch(server_address+'/shoe/stats?' + new URLSearchParams({
            name: name,
            tf: timeframe
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(bids.status === 200)
        {
            let data = await bids.json()
            setStats(data)

        }
        
    }


    return (
        <div className="flex flex-col gap-48">
           
            
            <div className="grid grid-cols-1 xl:grid-cols-2">
                <div className="sticky top-0 xl:col-span-2 z-10">
                    <Navbar />
                </div>
                <div className="bg-white p-[15px] sm:px-[40px] sm:pt-5">
                    <div className=" text-black">
                        <h1 className="font-bold leading-tight text-md sm:text-lg "> {shoe && shoe.title} </h1>
                        <h2 className=" text-sm"> {shoe && shoe.sku} </h2>
                        <span className=" text-sm"> {shoe && shoe.category} </span > 
                    </div>
                    <div className=" bg-white ">
                        <img 
                            src={"https://www.finedeal.eu/images/big/"+name+".jpg"}
                            alt="shoe photo"
                            className="mx-auto"
                            >
                        </img>
                    </div>
                </div>
                <div className="bg-background px-[15px] sm:px-[40px] pt-5">
                    <SelectSize selectedSize={sizeSelected} sizes={sizes} selectSize={setSizeSelected} className="text-white"/>
                    <div className="flex w-[97%] py-7 mx-auto gap-[15px] sm:gap-6">
                        <Button 
                            className="w-1/2 h-[55px] bg-background text-white hover:bg-gray/20 border border-white"
                            onClick={() => navigate({
                                pathname: '/sell',
                                search: `?${createSearchParams({
                                    name: name,
                                    size: sizeSelected,
                                })}`,
                            })}
                        >
                            <div className="flex flex-col">
                                <p className="font-bold text-white">Sell</p>
                                <p className="-mt-1 text-white">place ask</p>
                            </div>
                            
                        </Button>
                        <Button 
                            variant="orange"
                            className="w-1/2 h-[55px] text-black "
                            onClick={() => navigate({
                                pathname: '/buy',
                                search: `?${createSearchParams({
                                    name: name,
                                    size: sizeSelected,
                                })}`,
                            })}    
                        >
                            <div className="flex flex-col">
                                <p className="font-bold ">Buy</p>
                                <p className="-mt-1">place bid</p>
                            </div>
                        </Button>
                    </div>

                    <Separator className=" bg-white"/>

                    {/* <div className="hidden sm:flex  flex-col mx-auto mt-7  gap-7"> */}
                    <div className="flex  flex-col mx-auto mt-7  gap-7">
                        <SelectTimeframe timeframe={timeframe} setTimeframe={setTimeframe}/>
                        {graphSales && startingDate && endingDate && <PriceChart data={graphSales} startDate={startingDate} endDate={endingDate} />}
                        
                        <div className="flex flex-col gap-3">
                            <p className="text-md"> Shoe summary </p>
                            {stats && <ShoeStats 
                                salesNumber={stats.sales_number}
                                volatility={stats.volatility}
                                averagePrice={stats.average_selling_price}
                                minValue={stats.min_value}
                                maxValue={stats.max_value}
                                lastSalePrice={stats.last_sale_price}
                                averageSellingTimeDays={(stats.average_selling_time / 3600 / 24).toFixed(0)}
                                />}
                        </div>

                    </div>
                </div>
                <div className="bg-background px-[15px] sm:px-[40px] pt-10 xl:col-span-2">
                    <div className="flex flex-col gap-[50px]">
                        <div className="flex flex-col gap-2">
                            <p className="text-md">
                                Active bids
                            </p>
                            {bids && <SkuTableBids user={user} bids={bids} setBids={setBids} page={pageBids} totalPages={totalBids} setPage={setPageBids} limit={limitBids} setLimit={setLimitBids} refresh={refreshBids} setRefresh={setRefreshBids}/>}
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-md">
                                Active asks
                            </p>
                            {asks && <SkuTableAsks user={user} asks={asks} setAsks={setAsks} page={pageAsks} totalPages={totalAsks} setPage={setPageAsks} limit={limitAsks} setLimit={setLimitAsks} refresh={refreshAsks} setRefresh={setRefreshAsks}/>}
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-md">
                                Sales
                            </p>
                            {sales && <SkuTableSales sales={sales} setSales={setSales} page={pageSales} totalPages={totalSales} setPage={setPageSales} limit={limitSales} setLimit={setLimitSales}/>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer className="text-black"/>
            
        </div>
        )
    }
  
  export default ProductPage
