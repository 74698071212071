import { Label } from "../../ui/label"
import { Input } from "../../ui/input"
import { Textarea } from "../../ui/textarea"
import { SelectNation } from "../SelectNation"
import { useContext, useState, useEffect } from "react"
import AuthContext from "src/context/AuthContext"
import SelectDefaultCouriers from "../SelectDefaultCouriers"
import { SelectDefaultPaymentMethods } from "../SelectDefaultPaymentMethods"
import { Button } from "../../ui/button"
import { useNavigate } from "react-router-dom"
import { DeleteProfile } from "../DeleteProfile"

export default function ProfileForm(){
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    
    let [email, setEmail] = useState()
    let [username, setUsername] = useState()
    let [description ,setDescription] = useState()
    let [nation, setNation] = useState()
    let [marketNation, setMarketNation] = useState()
    let [defaultCouriers, setDefaultCouriers] = useState([])
    let [defaultPaymentMethods, setDefaultPaymentMethods] = useState([])
    let [couriers, setCouriers] = useState()
    let [paymentMethods, setPaymentMethods] = useState()
    
    let navigate = useNavigate()

    
    useEffect(() => {
        getUser()
        getCouriers()
        getPaymentMethods()
        getDefaultCouriers()
        getDefaultPaymentMethods()
    }, [])

    let getUser = async () => {
        let response = await fetch(server_address+"/user", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })
        
        
        if(response.status === 200)
        {
            let data = await response.json()
            setEmail(data.email)
            setUsername(data.username)
            setDescription(data.description)
            setNation(data.nation.name)
            setMarketNation(data.market_nation.name)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getCouriers = async () => {
        
        let response = await fetch(server_address+"/couriers", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let couriersArray = []
            data.map((courier) => {
                couriersArray.push(courier.name)
            })
            setCouriers(couriersArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getDefaultCouriers = async () => {
        
        let response = await fetch(server_address+"/couriers/default", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let defaultCouriersArray = []
            data.map((courier) => {
                defaultCouriersArray.push(courier.courier.name)
            })
            
            setDefaultCouriers(defaultCouriersArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getPaymentMethods = async () => {
        
        let response = await fetch(server_address+"/paymentmethods", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let paymentMethodsArray = []
            data.map((paymentMethod) => {
                paymentMethodsArray.push(paymentMethod.name)
            })
            setPaymentMethods(paymentMethodsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getDefaultPaymentMethods = async () => {
        
        let response = await fetch(server_address+'/paymentmethods/default', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let defaultPaymentMethodsArray = []
            data.map((paymentMethod) => {
                defaultPaymentMethodsArray.push(paymentMethod.payment_method.name)
            })
            setDefaultPaymentMethods(defaultPaymentMethodsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let handleSubmit = async () => {
        console.log(username, description, nation, marketNation, defaultCouriers, defaultPaymentMethods)
        
        let response = await fetch(server_address+'/user/edit', {
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            },
            body:JSON.stringify({
                'username': username,
                'nation':nation, 
                'market_nation': marketNation, 
                'description': description, 
                'default_couriers': defaultCouriers,
                'default_payment_methods': defaultPaymentMethods})
        })

    }

    return(
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1 sm:w-[400px]">
                    <Label className="text-sm sm:text-md" htmlFor="refs">Email</Label>
                    <Input className="" 
                                    id="refs" 
                                    type="text" 
                                    min="0" 
                                    defaultValue={email}
                                    disabled
                    />
                </div>
                <Button 
                    variant="background"
                    size="sm" 
                    className="w-[180px]"  
                    onClick={() => navigate("/changepassword")}>
                    Change password
                </Button>
            </div>


            <div className="flex flex-col gap-1 sm:w-[400px]">
                <Label className="text-sm sm:text-md" htmlFor="refs">Username</Label>
                <Input className="" 
                                id="refs" 
                                type="text" 
                                min="0" 
                                defaultValue={username}
                                onChange={(e) => setUsername(e.target.value)}
                        />
                <p className="text-sm text-muted-foreground">
                    This is your public username
                </p>

            </div>
            <div className="flex flex-col gap-2">
                <Label className="text-sm sm:text-md" htmlFor="message-2">Contact info</Label>
                <div className="flex flex-col gap-1">
                    <Textarea 
                        defaultValue={description} 
                        id="bio" 
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <p className="text-sm text-muted-foreground">
                        Add ways for people to contact you
                    </p>
                </div>
            </div>

            <div className="flex flex-col gap-1 sm:w-[400px]">
                <Label className="text-sm sm:text-md" htmlFor="nation">Your nation</Label>
                <SelectNation nation={nation} setNation={setNation}/>
                <p className="text-sm text-muted-foreground">
                    Nation of origin
                </p>
            </div>

            <div className="flex flex-col gap-1 sm:w-[400px]">
                <Label className="text-sm sm:text-md" htmlFor="market_nation">Market nation</Label>
                <SelectNation nation={marketNation} setNation={setMarketNation}/>
                <p className="text-sm text-muted-foreground">
                    Change market nation to interact with people in other nations
                </p>
            </div>

            <div className="flex flex-col gap-1">
                {couriers && defaultCouriers && <SelectDefaultCouriers couriers={couriers} selectedCouriers={defaultCouriers} setCouriers={setDefaultCouriers} />}
                {paymentMethods && defaultPaymentMethods && <SelectDefaultPaymentMethods selectedPaymentMethods={defaultPaymentMethods} paymentMethods={paymentMethods} setPaymentMethods={setDefaultPaymentMethods}/>}
            </div>
            <div className="flex gap-1">
                <Button type="submit" onClick={() => handleSubmit()}>
                    Save
                </Button>
            </div>
            <div className="flex gap-1" >
                <DeleteProfile />
            </div>

            

        </div>
    )
}