// import React from "react";
// import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
//   CartesianGrid
// } from "recharts";
// import DateUtils from "./DateUtils";
// import CustomTooltip from "./CustomTooltip";

// const dateFormatter = date => {
//   // return format(new Date(date), "dd/MMM");
//   return format(new Date(date), "MMM");
// };

// /**
//  * get the dates between `startDate` and `endSate` with equal granularity
//  */
// const getTicks = (startDate, endDate, num) => {
//   const diffDays = differenceInCalendarDays(endDate, startDate);

//   let current = startDate,
//     velocity = Math.round(diffDays / (num - 1));

//   const ticks = [startDate.getTime()];

//   for (let i = 1; i < num - 1; i++) {
//     ticks.push(add(current, { days: i * velocity }).getTime());
//   }

//   ticks.push(endDate.getTime());
//   return ticks;
// };

// /**
//  * Add data of the date in ticks,
//  * if there is no data in that date in `data`.
//  *
//  * @param Array<number> _ticks
//  * @param {*} data
//  */
// const fillTicksData = (_ticks, data) => {
//   const ticks = [..._ticks];
//   const filled = [];
//   let currentTick = ticks.shift();
//   let lastData = null;
//   for (const it of data) {
//     if (ticks.length && it.date > currentTick && lastData) {
//       filled.push({ ...lastData, ...{ date: currentTick } });
//       currentTick = ticks.shift();
//     } else if (ticks.length && it.date === currentTick) {
//       currentTick = ticks.shift();
//     }

//     filled.push(it);
//     lastData = it;
//   }

//   return filled;
// };

// function PriceChart({data, startDate, endDate}){


//   // const domain = [dataMin => dataMin, () => endDate.getTime()];
//   const domain = [dataMin => dataMin, endDate => endDate];
//   const ticks = getTicks(startDate, endDate, 5);
//   const filledData = fillTicksData(ticks, data);

//   return (
//     <div>
//       <ResponsiveContainer aspect={2.5}>
//         <AreaChart
//           data={data}
//           margin={{
//             top: 10,
//             right: 0,
//             bottom: 10,
//             left: 0
//           }}
//         >
//           <CartesianGrid
//             vertical={false}
        
//         >
            
//           </CartesianGrid>
//           <XAxis
//             dataKey="date"
//             hasTick
//             tickLine={false}
//             scale="time"
//             tickFormatter={dateFormatter}
//             type="number"
//             domain={domain}
//             ticks={ticks}
//             stroke="#FFFFFF"
//             dy={10}
//             fontWeight={300}
//           />
//           <YAxis 
//             tickCount={6}
//             tickLine={false}
//             // tickFormatter={(value) => (value !== 0 ? `€ ${value}`: '')}
//             tickFormatter={(value) => (`€ ${value}`)}
//             stroke="#FFFFFF"
//             dx={-5}
//             fontWeight={300}
//             axisLine={false}
//           />
//           <Tooltip content={<CustomTooltip />} />
//           <Area
//             type="linear"
//             dataKey="val"
//             stroke="#ee882b"
//             strokeWidth={2}
//             fill="#ee882b"
//             fillOpacity={0.5}
//           />
//         </AreaChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default PriceChart;


import React from "react";
import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from "recharts";
import DateUtils from "./DateUtils";
import CustomTooltip from "./CustomTooltip";

const dateFormatter = date => {
  // return format(new Date(date), "dd/MMM");
  return format(new Date(date), "MMM");
};

/**
 * get the dates between `startDate` and `endSate` with equal granularity
 */
const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
const fillTicksData = (_ticks, data) => {
  const ticks = [..._ticks];
  const filled = [];
  let currentTick = ticks.shift();
  let lastData = null;
  for (const it of data) {
    if (ticks.length && it.date > currentTick && lastData) {
      filled.push({ ...lastData, ...{ date: currentTick } });
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }

    filled.push(it);
    lastData = it;
  }

  return filled;
};

function PriceChart({data, startDate, endDate}){


  // const domain = [dataMin => dataMin, () => endDate.getTime()];
  const domain = [dataMin => dataMin, endDate => endDate];
  const ticks = getTicks(startDate, endDate, 5);

  return (
    <div>
      <ResponsiveContainer aspect={2.5}>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            bottom: 10,
            left: 0
          }}
        >
          <CartesianGrid
            vertical={false}
        
        >
            
          </CartesianGrid>
          <XAxis
            dataKey="date"
            hasTick
            tickLine={false}
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={domain}
            ticks={ticks}
            stroke="#FFFFFF"
            dy={10}
            fontWeight={300}
          />
          <YAxis 
            tickCount={6}
            tickLine={false}
            // tickFormatter={(value) => (value !== 0 ? `€ ${value}`: '')}
            className="text-xs sm:text-sm"
            tickFormatter={(value) => (`€${value}`)}
            stroke="#FFFFFF"
            dx={-5}
            fontWeight={300}
            axisLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="linear"
            dataKey="val"
            stroke="#ee882b"
            strokeWidth={2}
            fill="#ee882b"
            fillOpacity={0.5}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PriceChart;
