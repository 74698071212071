import ShoeCard from "./ShoeCard"
import { useState } from "react"
import { useContext } from "react"
import AuthContext from "src/context/AuthContext"
import { useEffect } from "react"
import { Link } from "react-router-dom"

function TopUsers(){
    let [mostActive, setMostActive] = useState([])
    let [bestSellers, setBestSellers] = useState([])
    let [bestBuyers, setBestBuyers] = useState([])


    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    useEffect(() => {
        getMostActive()
        getBestBuyers()
        getBestSellers()
    }, [])


    let getMostActive = async () => {
        
        let response = await fetch(server_address+"/users/most_active", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })
        

        if(response.status === 200)
        {
            let data = await response.json()
            setMostActive(data)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getBestBuyers = async () => {
        
        let response = await fetch(server_address+"/users/best_buyers", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setBestBuyers(data)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getBestSellers = async () => {
        
        let response = await fetch(server_address+"/users/best_sellers", {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setBestSellers(data)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }


    return(
        // <div className="flex flex-row w-[100%] justify-between gap-[80px]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[80px] gap-y-[50px]">
            <div className="flex-grow">
                <h1 className="font-bold text-md">
                    Most active users
                </h1>
                <div>
                    <table className="w-full">
                        <tr className="font-semibold">
                            <td>
                                #
                            </td>
                            <td>
                                username
                            </td>
                            <td className="flex flex-row-reverse">
                                active offers
                            </td>
                        </tr>
                        {mostActive.map((user, key) => (
                            
                                <tr className="">
                                    <td className="">
                                        {key + 1} 
                                    </td>
                                    <td>
                                        {user.username}
                                    </td>
                                    <td className="flex flex-row-reverse">
                                    {user.count}
                                    </td>   
                                </tr>
                            
                        ))}
                    </table>
                </div>
            </div>
            <div className="flex-grow">
                <h1 className="font-bold text-md">
                    Top Buyers
                </h1>
                <div>
                <table className="w-full">
                        <tr className="font-semibold">
                            <td>
                                #
                            </td>
                            <td>
                                username
                            </td>
                            <td className="flex flex-row-reverse">
                                Total purchases
                            </td>
                        </tr>
                        {bestBuyers.map((user, key) => (
                            
                                <tr>
                                    <td className="">
                                        {key + 1} 
                                    </td>
                                    <td>
                                        {user.username}
                                    </td>
                                    <td className="flex flex-row-reverse">
                                    {user.count}
                                    </td>   
                                </tr>
                            
                        ))}
                    </table>
                </div>
            </div>
            <div className="flex-grow">
                <h1 className="font-bold text-md">
                    Top sellers
                </h1>
                <div>
                <table className="w-full">
                        <tr className="font-semibold">
                            <td>
                                #
                            </td>
                            <td>
                                username
                            </td>
                            <td className="flex flex-row-reverse">
                                Total sales
                            </td>
                        </tr>
                        {bestSellers.map((user, key) => (
                            
                                <tr>
                                    <td className="">
                                        {key + 1} 
                                    </td>
                                    <td>
                                        {user.username}
                                    </td>
                                    <td className="flex flex-row-reverse">
                                    {user.count}
                                    </td>   
                                </tr>
                            
                        ))}
                    </table>
                </div>
            </div>
        </div>
    )

}

export default TopUsers