import React, { useState } from 'react'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import {useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/custom/Forms/LoginForm';
import { Link } from 'react-router-dom';
import { NavbarMinimal } from '../components/custom/NavbarMinimal';
import { Navbar } from '../components/custom/Navbar';
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import FormAlert from '../components/custom/FormAlert';

function ChangePassword() {
    let {server_address, authTokens, logoutUser} = useContext(AuthContext)
    let [missingFields, setMissingFields] = useState(false)
    let [error, setError] = useState(false)
    let [passwordDifference, setPasswordDifference] = useState(false)
    let navigate = useNavigate()

    let handleSubmit = async () => {
        let currentPassword = document.getElementById("current-password").value
        let newPassword = document.getElementById("new-password").value
        let confirmPassword = document.getElementById("confirm-password").value


        if (currentPassword !== "" && newPassword !== "" && confirmPassword !== "")
        {
            setMissingFields(false)
            if (newPassword === confirmPassword){
                setPasswordDifference(false)
                
                let response = await fetch(server_address+'/user/change_password', {
                method:'PUT',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + String(authTokens?.access)
                },
                body:JSON.stringify({'password': currentPassword, 'new_password': newPassword, 'password_confirmation': confirmPassword})
                })

                if(response.status == 200){
                    navigate("/profile")
                }
                
                else if(response.status == 401){
                    logoutUser()
                }

                else{
                    setError(true)
                }
            }

            else{
                setPasswordDifference(true)
            }

        }

        else{
            setMissingFields(true)
        }

    }

    return (
      <div className="flex flex-col h-dvh">
        <Navbar />      
        <div className="flex flex-col h-full justify-center">
            <div className="w-[85%] max-w-sm mx-auto">
                {missingFields && <FormAlert error={"Please fill in all the fields."} />}
                {passwordDifference && <FormAlert error={"The two passwords do not match."} />}
                {error && <FormAlert error={"There was an error, please try again later."} />}    
            </div>
            
            <Card className="w-[85%] max-w-sm mx-auto">
                <CardHeader className="space-y-1">
                    <CardTitle>Change Password</CardTitle>
                    <CardDescription>Please enter your current password and a new password to continue</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="current-password">Current Password</Label>
                        <Input id="current-password" required type="password" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="new-password">New Password</Label>
                        <Input id="new-password" required type="password" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="confirm-password">Confirm New Password</Label>
                        <Input id="confirm-password" required type="password" />
                    </div>
                    <Button className="w-full" type="submit" onClick={() => handleSubmit()}>
                        Change Password
                    </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
        
    </div>
    );
  }
  
  export default ChangePassword;