import { Navbar } from "../components/custom/Navbar";
import Stats from "../components/custom/Stats";
import Bestselling from "../components/custom/Bestselling";
import BestsellingBrand from "../components/custom/BestsellingBrand";
import Footer from "../components/custom/Footer";
import MostActive from "../components/custom/MostActive";
import TopUsers from "../components/custom/TopUsers";


function Home() {
    return (
      <div className=" bg-white text-black">
        <div className="sticky top-0 z-10">
          <Navbar />
        </div>



        {/* <div className="max-w-[1450px] mx-[15px] mt-2 sm:mx-[40px] 2xl:mx-auto ">
          <div className="flex flex-col gap-[50px] lg:flex-row">
            <Stats ></Stats>
            <Bestselling></Bestselling>
          </div>
          <div className="">
            <BestsellingBrand brand={"Nike"}></BestsellingBrand>
            <BestsellingBrand brand={"Adidas"}></BestsellingBrand>
          </div>
        </div> */}

        {/* <div className="max-w-[1450px] mx-[15px] mt-2 grid gap-3 grid-cols-1 lg:mt-4 lg:grid-cols-2 sm:mx-[40px] 2xl:mx-auto"> */}
        <div className="max-w-[1450px] mx-[15px] mt-2 grid gap-6 grid-cols-1 lg:mt-4 lg:grid-cols-2 sm:mx-[40px] 2xl:mx-auto">
            {/* <Stats ></Stats>
            <Bestselling></Bestselling> */}
            
            {/* <div className="lg:col-span-2">
              <BestsellingBrand  brand={"Nike"}></BestsellingBrand>
            </div>
            <div className="lg:col-span-2">
              <BestsellingBrand  brand={"Adidas"}></BestsellingBrand>
            </div> */}
                        <div className="lg:col-span-2">
              <Bestselling></Bestselling>
            </div>
            <div className="lg:col-span-2">
              <MostActive></MostActive>
            </div>

            <div className="lg:col-span-2">
              <TopUsers />
            </div>
        </div>



        <div className="mt-[300px]">
          <Footer />
        </div>
        
        

        
  
      </div>
    );
  }
  
  export default Home;