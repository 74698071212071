import React from "react";
import {
  BarChart as BarGraph,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar
} from "recharts";

export default function DashboardChart({data}) {
  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <BarGraph data={data}>
        <XAxis
          dataKey={"month"}
          tickLine={false}
          axisLine={false}
          stroke="#ffffff"
          fontSize={16}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          stroke="#ffffff"
          fontSize={16}
          tickFormatter={(value) => `$${value}`}
        />
        <Bar 
            dataKey={"value"} 
            radius={[4, 4, 0, 0]} 
            // fill="#ffffff"
            fill="#ee882b"
        />
      </BarGraph>
    </ResponsiveContainer>
  );
}