import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "../../lib/utils"
import { Button } from "src/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover"

import { useState, useEffect, useContext } from "react"
import AuthContext from "src/context/AuthContext"

export function SelectNation({nation, setNation, className}) {
  let {server_address, authTokens, logoutUser} = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  let [nations, setNations] = useState([])

  useEffect(() => {
    getNations()
  }, [])

  let getNations = async () => {
    let response = await fetch(server_address+'/nations', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
            }
        })

        if(response.status === 200)
        {
            let data = await response.json()
            let nationsArray = []
            data.map((nation) => {
                nationsArray.push(nation.name)
            })
            setNations(nationsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
  }
  
  return (
    <div className={cn("", className)}>
      {nations &&
      <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {nation}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search nations..." />
          <CommandEmpty>No nations found.</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {nations.map((n, index) => (
                <CommandItem
                  key={index}
                  value={n}
                  onSelect={(currentValue) => {
                    setNation(currentValue)
                    setOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      nation === n ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {n}
                </CommandItem>
              )
              )}
            </CommandList>
            
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
    }
    </div>
    
    
  )
}

