import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../ui/card"
import { Button } from "../../ui/button"
import { DotsVerticalIcon } from "@radix-ui/react-icons"

const DashboardCardSummary = ({label, amount, description, icon}) => {
    return(
        <Card className="w-[100%]">
            <CardContent className="py-5">
                <div className="flex flex-col gap-4">
                    <p className="flex justify-between">
                        {label}
                        {icon}
                    </p>
                    <div className="flex flex-col">
                        <p className="text-md font-bold text-orange">
                            {amount}
                        </p>
                        {/* <p>
                            {description}
                        </p> */}
                    </div>
                    </div>
                
            </CardContent>
        </Card>
    )
}

export default DashboardCardSummary