import { createContext, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {

    let server_address = "https://www.finedeal.eu/api"
    //let server_address = "http://test.finedeal.eu:8000/api"
    

    // let server_address = "127.0.0.1"

    let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    let loginUser = async (email, password) => {

        let response = await fetch(server_address+"/user/token", {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'email': email, 'password': password})
        })
        
        if(response.status === 200){
            //se è tutto ok
            let data = await response.json()
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            navigate('/home');
            return response.status
        }
        
        else{
            return response.status
        }

    }
    
    let RegisterUser = async (username, email, password, confirm_password, description, nation) => {
    
        //console.log(username, email, password, confirm_password)
        let response = await fetch(server_address+"/user/register", {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                'email': email, 
                'username':username, 
                'password': password, 
                'confirm_password': confirm_password,
                'description': description,
                'nation': nation
            })
        })
        
        if(response.status === 200){
            //se è tutto ok
            return response.status
        }
        
        else{
            const body = await response.json();
            // console.log(body)
            return body
        }
    }

    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        //redirect a login
    }

    let updateToken = async ()=> {
        let response = await fetch(server_address+"/user/token/refresh", {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh':authTokens?.refresh})
        })

        let data = await response.json()
        
        if (response.status === 200){
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        }else{
            logoutUser()
        }

        if(loading){
            setLoading(false)
        }
        
        
    }
    
    let isLoggedIn = () => {
        if (user)
        {
            return true
        }else{
            return false
        }
    }


//variabili sopra funzioni sotto utilizzabili da fuori. Per es vedi login
    let contextData = {
        user:user,
        authTokens:authTokens,
        server_address:server_address,

        loginUser:loginUser,
        logoutUser:logoutUser,
        RegisterUser:RegisterUser,
        isLoggedIn:isLoggedIn,
    }
    
    useEffect(()=> {

        if(loading){
            updateToken()
        }

        let fourMinutes = 1000 * 60 * 4

        let interval =  setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, fourMinutes)
        return ()=> clearInterval(interval)

    }, [authTokens, loading])

    return(
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}
