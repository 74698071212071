import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
 } from "src/components/ui/table"

import { Button } from "src/components/ui/button"
import Pagination from "../Pagination"


function SkuTableSales({sales, page, totalPages, setPage, limit, setLimit}){
    return(
      <div className="flex flex-col gap-4">

        <div className="w-[100%] rounded-lg border border-gray-200 dark:border-gray-700">
        <Table>
          <TableHeader>
            <TableRow className="bg-[#18191B] hover:bg-[#18191B]">
              <TableHead className="">
                <div className="flex justify-center">
                  Size
                </div>  
              </TableHead>
              <TableHead className="">
                <div className="flex justify-center">
                  Price
                </div>  
              </TableHead>

              <TableHead className="">
                <div className="flex justify-center">
                  Date
                </div>
              </TableHead>

              <TableHead className="">
                <div className="flex justify-center">
                  Seller
                </div>
              </TableHead>

              <TableHead className="">
                <div className="flex justify-center">
                  Buyer
                </div>
              </TableHead>

            </TableRow>
          </TableHeader>
          <TableBody>
            {sales.map((offer) => (
              <TableRow>
                
                <TableCell>
                  <div className="flex justify-center">
                    {offer.size}
                  </div>  
                </TableCell>
                
                <TableCell >
                  <div className="flex justify-center">
                  €{offer.price}
                  </div>  
                </TableCell>

                <TableCell>
                  <div className="flex justify-center">
                    <span>
                      {new Date(offer.acceptation_date).getDate()}/{new Date(offer.acceptation_date).getMonth() + 1}/{new Date(offer.acceptation_date).getFullYear()}
                    </span>
                  </div>
                </TableCell>

                <TableCell>
                  <div className="flex justify-center">
                  {offer.deals_type == "Ask" ?
                    offer.creator ? offer.creator : "delete user" : 
                    offer.acceptor ? offer.acceptor : "delete user"
                  }
                  </div> 
                </TableCell>

                <TableCell>
                  <div className="flex justify-center">
                  {offer.deals_type == "Bid" ? 
                    offer.creator ? offer.creator : "delete user" : 
                    offer.acceptor ? offer.acceptor : "delete user"}
                  </div> 
                </TableCell>
                
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="flex flex-row-reverse">
        <Pagination page={page} totalPages={totalPages} setPage={setPage} limit={limit} setLimit={setLimit}/>
      </div>
      </div>
    )
}


export default SkuTableSales