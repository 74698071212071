function email_valid(email) {
    // Pattern per il controllo del formato dell'email
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Utilizzo della funzione test per verificare il formato
    return pattern.test(email);
}




export default email_valid
