import Footer from "../components/custom/Footer"
import { Navbar } from "../components/custom/Navbar"

const Terms = () => {


    return (
        <div>
            <div className="bg-white sticky top-0 z-10">
                <Navbar />
            </div>
            <div className="p-[15px] sm:p-11">
                <h1 className="font-bold text-md sm:text-lg">
                    Terms and conditions
                </h1>
                <ol className="mt-4 flex flex-col gap-5">
                    <li>
                        Welcome to Fine Deal, an online marketplace for buying and selling sneakers. Before using the services provided by Fine Deal, 
                        please carefully read and understand the following Terms and Conditions. By using our site, you agree to be bound by the following terms:
                    </li>
                    <li>
                        <h1 className="font-bold">
                            1. Acceptance of Terms
                        </h1>
                        <p>
                            By accessing or using Fine Deal, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to any of these terms, please do not use our site.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            2. Description of Services
                        </h1>
                        <p>
                            Fine Deal provides an online platform that allows users to buy and sell sneakers. 
                            We merely facilitate the transaction between buyers and sellers and are not a party to any sales contract between users. 
                            We are not responsible for the quality, compliance, safety, or legality of the listed items, nor for the ability of sellers to sell items or the ability of buyers to pay for items.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            3. User Responsibilities
                        </h1>
                        <p>
                            Users are responsible for their actions on Fine Deal. We strive to maintain a safe and transparent environment, 
                            but we cannot guarantee the reliability or accuracy of information provided by users. 
                            Users must comply with all applicable laws and regulations regarding the buying and selling of sneakers.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            4. User Communications and Content
                        </h1>
                        <p>
                            Users are responsible for the content they post on Fine Deal.
                            We reserve the right to remove any content that violates our Terms and Conditions or that we deem inappropriate, offensive, or fraudulent.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            5. Changes to Terms and Conditions
                        </h1>
                        <p>
                            We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our site. 
                            By continuing to use our services after the posting of changes, you agree to be bound by the modified Terms and Conditions.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            6. Limitation of Liability
                        </h1>
                        <p>
                            In no event shall [Your Site Name], nor its officers, employees, agents, or affiliates, be liable for any direct, indirect, special, consequential,
                            or punitive damages arising from the use or inability to use our services.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            7. Applicable Law
                        </h1>
                        <p>
                            These Terms and Conditions are governed and interpreted in accordance with the laws of Italy, without regard to its conflict of laws principles.
                        </p>
                    </li>
                    <li>
                        <h1 className="font-bold">
                            8. Miscellaneous Provisions
                        </h1>
                        <p>
                            If any provision of these Terms and Conditions is deemed invalid or unenforceable by a competent court,
                            such provision shall be interpreted to reflect the original intent as closely as possible, and the remaining provisions shall remain in full force and effect.
                        </p>
                    </li>
                    <li>
                        For any questions or clarifications regarding our Terms and Conditions, please contact us at support@finedeal.eu.
                    </li>
                    <li>
                        Thank you for choosing Fine Deal!
                    </li>
                </ol>
            </div>
            <div className="mt-[400px]">
                <Footer />
            </div>
            
        </div>
        )
    }
  
  export default Terms