import { DialogOverlay } from "@radix-ui/react-dialog";
import { Button } from "../../ui/button";
import { ChevronRight, ChevronsLeft, ChevronLeft, ChevronsRight } from "lucide-react";
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "../../../lib/utils"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover"
import { useState } from "react";

export default function Pagination ({page, setPage, totalPages, limit, setLimit}){
    const [open, setOpen] = useState(false)
    const limits = [
        5,
        10,
        20,
        50
    ]

    return(
        <div className="flex flex-row-reverse sm:flex-row justify-between items-center w-[500px] text-[14px]">
            <div className="hidden sm:flex gap-3 font-medium items-center ">
                <p>
                    Rows per page
                </p>
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="justify-between gap-3 h-[32px] text-[14px] items-center"
                        >
                        {limit}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0">
                        <Command>
                        <CommandGroup>
                            <CommandList>
                            {limits.map((l, index) => (
                                <CommandItem
                                key={index}
                                value={l}
                                onSelect={(currentValue) => {
                                    setLimit(currentValue)
                                    setOpen(false)
                                }}
                                >
                                <Check
                                    className={cn(
                                    "mr-2 h-4 w-4",
                                    limit === l ? "opacity-100" : "opacity-0"
                                    )}
                                />
                                {l}
                                </CommandItem>
                            )
                            )}
                            </CommandList>
                            
                        </CommandGroup>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
            <div className="flex items-center gap-6">
                <p className="font-medium">
                    Page {page} of {totalPages}
                </p>
                <div className="flex gap-2">
                    
                    {page === 1 ? 
                    <Button variant="background" size="smicon" disabled>
                        <ChevronsLeft className="w-4 h-4"/>
                    </Button> : 
                    <Button variant="background" size="smicon" onClick={() => setPage(1)}>
                        <ChevronsLeft className="w-4 h-4"/>
                    </Button>}

                    {page < 2 ? 
                    <Button variant="background" size="smicon" disabled>
                        <ChevronLeft className="w-4 h-4"/>
                    </Button> :
                    <Button 
                        variant="background" 
                        size="smicon"
                        onClick={() => setPage(page-1)}>

                        <ChevronLeft className="w-4 h-4"/>
                    </Button>
                    }
                    
                    {page > totalPages - 1 ? 
                    <Button variant="background" size="smicon" disabled>
                        <ChevronRight className="w-4 h-4"/>
                    </Button> : 
                    <Button variant="background" size="smicon" onClick={() => setPage(page+1)}>
                        <ChevronRight className="w-4 h-4"/>
                    </Button>
                    }
                

                    {page === totalPages ? 
                    <Button variant="background" size="smicon" disabled>
                        <ChevronsRight className="w-4 h-4"/>
                    </Button> : 
                    <Button variant="background" size="smicon" onClick={() => setPage(totalPages)}>
                        <ChevronsRight className="w-4 h-4"/>
                    </Button>}

                    

                </div>
            </div>
            
        </div>
    )
}