import { useNavigate } from "react-router-dom";

const Password = ({setLaunch}) => {
    const navigate = useNavigate()


    setLaunch(true)
    navigate("/")
    return(
        <>
        loading
        </>
    )
}
  
  export default Password