import Footer from "../components/custom/Footer"
import { Navbar } from "../components/custom/Navbar"

const Rules = () => {


    return (
        <div>
            <div className="bg-white">
                <Navbar />
            </div>
            
            <div className="p-[15px] sm:p-11 h-dvh">
                <h1 className="font-bold text-md sm:text-lg">
                    Marketplace rules
                </h1>

                <ol className="flex flex-col mt-4 gap-5">
                    <li>
                        1) Every bid/ask is a binding offer which means that you can't decline if someone accepts your offer. This could lead to a ban or a suspension of your account.
                    </li>
                    <li>
                        2) Every price has national shipping included with one of the accepted couriers. You may buy from another nation but you need to compensate the shipping price difference. 
                    </li>
                    <li>
                        3) Prices are not negotiable.
                    </li>
                    <li>
                        4) Fine Deal does not take any responsability on the deals
                    </li>
                    <li>
                        5) Prices do not include shipping insurance, if the acceptor wants to ensure the package he is going to pay the price for it.
                    </li>
                    <li>
                        6) The minimum refs field rappresent the minimum number of references that the person who accepts need to have. This number can be demostrated from our ref system or from other sources such as Instagram or Twitter 
                    </li>
                    <li>
                        7) When an offer is accepted the buyer has up to 24 hours to pay and the seller has up to 3 working days to ship the package since he received the payment
                    </li>
                    <li>
                        8) Prices do not include other fees such as Paypal G&S
                    </li>
                    <li>
                        9) If nobody reports a problem about an offer, that offer after 10 days is going to be considered completed. The buyer and the seller will automacally get a buyer ref and a seller ref
                    </li>
                </ol>
            </div>

            <div className="mt-[400px]">
                <Footer />
            </div>
           
        </div>
        )
    }
  
  export default Rules