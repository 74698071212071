import { Navbar } from "../components/custom/Navbar"
import { useContext, useState, useEffect } from "react"
import AuthContext from "../context/AuthContext"
import { Button } from "../components/ui/button"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import UserTableSales from "../components/custom/tables/userTables/UserTableSales"
import PageTitle from "../components/custom/ProfileNavigation/PageTitle"
import UserTablePendingSales from "../components/custom/tables/userTables/UserTablePendingSales"
import Footer from "../components/custom/Footer"

const SellingCompleted = () => {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [pending, setPending] = useState()
    let [sales, setSales] = useState()
    let [pagePending, setPagePending] = useState(1)
    let [pageSales, setPageSales] = useState(1)
    let [totalPending, setTotalPending] = useState()
    let [totalSales, setTotalSales] = useState()
    let [limitPending, setLimitPending] = useState(10)
    let [limitSales, setLimitSales] = useState(10)
    let [refresh, setRefresh] = useState(false)
    
    useEffect(() => {
        getPending()
        getSales()
    }, [])
    
    useEffect(() => {
        getPending()
    }, [pagePending, limitPending, refresh])

    useEffect(() => {
        getSales()
    }, [pageSales, limitSales])
    
    let getSales = async () => {
        let sales = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "user",
            deals_type: "Ask",
            status: "completed",
            limit: limitSales,
            page: pageSales
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })
        
        if(sales.status === 200)
        {
            let data = await sales.json()
            setSales(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalSales(data.pagination.total_pages) : setTotalSales(1)

        } else if(pending.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getPending = async () => {
        
        let pending = await fetch(server_address+'/marketplace?' + new URLSearchParams({
            type: "user",
            deals_type: "Ask",
            status: "pending",
            limit: limitPending,
            page: pagePending
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        if(pending.status === 200)
        {
            let data = await pending.json()
            setPending(data.marketplace)
            data.pagination.total_pages > 0 ? setTotalPending(data.pagination.total_pages) : setTotalPending(1)

        } else if(pending.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    return (
        <div >
            <div className="bg-white sticky top-0 z-10">
                <Navbar />
            </div>
            <div className="flex">
                <div className="sticky top-0 z-1 self-start">
                    <ProfileNavigation />
                </div>
                <div className="p-[15px] sm:p-6 w-full overflow-auto">
                    <PageTitle title="Pending sales" />
                    <div className="mt-3">
                        {pending && <UserTablePendingSales pending={pending} setPending={setPending} page={pagePending} setPage={setPagePending} totalPages={totalPending} limit={limitPending} setLimit={setLimitPending} refresh={refresh} setRefresh={setRefresh}/>}
                    </div>
                    {/* <PageTitle title="Sales history" /> */}
                    <h1 className="text-md font-bold mt-5"> Sales history </h1>
                    <div className="mt-3">
                        {sales && <UserTableSales sales={sales} setSales={setSales} page={pageSales} setPage={setPageSales} totalPages={totalSales} limit={limitSales} setLimit={setLimitSales}/>}
                    </div>
                    
                            
                </div>
            </div>
            <div className="mt-[500px]">
                <Footer></Footer>
            </div>
            
        </div>
        )
    }

  export default SellingCompleted