import { useState, useContext } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import email_valid from "../utils/email_valid";
import AuthContext from "../context/AuthContext";
import { ButtonLoading } from "../components/custom/ButtonLoading";
import {default as logoFullW} from "../images/FD-logofullW.svg"

function PreLaunch({setLaunch}) {
    let {server_address} = useContext(AuthContext)
    var countDownDate = new Date("Apr 15, 2024 21:00:00").getTime();

    let [days, setDays] = useState(0)
    let [hours, setHours] = useState(0)
    let [minutes, setMinutes] = useState(0)
    let [seconds, setSeconds] = useState(0)

    let [email, setEmail] = useState()

    let [error, setError] = useState(false)
    let [loading, setLoading] = useState(false)
    let [subscribed, setSubscribed] = useState(false)

    var timer = setInterval(function() {
        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        if (timeleft <= 0)
        {
            setLaunch(true)
        }

        setDays(Math.floor(timeleft / (1000 * 60 * 60 * 24)))
        setHours(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        setMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)))
        setSeconds(Math.floor((timeleft % (1000 * 60)) / 1000))
    }, 1000)

    let handleSubmit = async () => {
        if (email_valid(email)){
            setError(false)
            setLoading(true)

            let response = await fetch(server_address+"/marketing/newsletter", {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    'email': email
                })
            })
            
            if (response.status === 200)
            {
                setLoading(false)
                setSubscribed(true)
                setEmail("")
            }

            else if (response.status === 401){
                setSubscribed(true)
            }

            else{
                setError(true)
            }
        }
        else{
            setError(true)
        }
    }
  
    return (
      <div className="w-full">
      
        <div className="flex flex-col items-center absolute top-[48%] left-1/2 -translate-y-1/2 -translate-x-1/2 w-full gap-[10px] sm:gap-[10px]">
            <img src={logoFullW} alt="Logo" className="px-[5%] max-w-[1000px] py-[5px] lg:py-[2%] "/>
            <div className="w-full flex flex-col items-center gap-[5px] sm:gap-[10px]">
                <p className="sm:text-md font-bold">
                    Launching soon...
                </p>
                <div className="flex gap-[30px] sm:gap-[60px]">
                    <div className="flex flex-col gap-0 items-center">
                        <span className="text-[30px] sm:text-[50px] font-bold text-orange">{days}</span>
                        <p className="text-xs sm:text-sm">Days</p>
                    </div>
                    <div className="flex flex-col gap-0 items-center">
                        <span className="text-[30px] sm:text-[50px] font-bold text-orange">{hours}</span>
                        <p className="text-xs sm:text-sm">Hours</p>
                    </div>
                    <div className="flex flex-col gap-0 items-center">
                        <span className="text-[30px] sm:text-[50px] font-bold text-orange">{minutes}</span>
                        <p className="text-xs sm:text-sm">Minutes</p>
                    </div>
                    <div className="flex flex-col gap-0 items-center">
                        <span className="text-[30px] sm:text-[50px] font-bold text-orange">{seconds}</span>
                        <p className="text-xs sm:text-sm">Seconds</p>
                    </div>
                </div>
                    
                
            </div>
        </div>
        <div className="absolute bottom-[30px] text-white w-full flex flex-col items-center gap-2 sm:gap-4" >
            <p className="text-xs sm:text-sm">
                Notify me when it's ready
            </p>
            <div className="flex gap-2">
                <Input 
                    className="h-[35px] text-xs sm:text-sm sm:h-[40px]"
                    placeholder={
                        subscribed ? 
                        "Already subscribed" : 
                        "Enter your email"
                        
                    }
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    >
                </Input>
                
                {subscribed ? 
                <Button 
                    disabled
                    variant="orange"
                    className="h-[35px] text-xs sm:text-sm sm:h-[40px]"
                    onClick={() => handleSubmit()}>
                    Subscribe
                </Button> : 
                loading ? 
                <ButtonLoading /> :
                <Button 
                    variant="orange"
                    className="h-[35px] text-xs sm:text-sm sm:h-[40px]"
                    onClick={() => handleSubmit()}>
                    Subscribe
                </Button>

                }
            </div>
        </div>
      </div>
        
    );
  }
  
  export default PreLaunch;