import { useContext } from "react"
import { Button } from "../../ui/button"
import AuthContext from "src/context/AuthContext"
import { ChevronLeft } from "lucide-react"
import { useNavigate } from "react-router-dom"


const PageTitle = ({title}) => {
    let {logoutUser} = useContext(AuthContext)
    const navigate = useNavigate()

    return(
        <div className="flex items-center gap-2 ">
            <Button
                variant="icon"  
                className="p-0 sm:hidden"
                onClick={() => navigate("/account")}
                >
                <ChevronLeft />
            </Button>
            <h1 className="text-md font-bold">{title}</h1>
        </div>
    )
        
    }

  export default PageTitle