import React from 'react'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import {useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/custom/Forms/LoginForm';
import { Link } from 'react-router-dom';
import { NavbarMinimal } from '../components/custom/NavbarMinimal';

function Login() {
    let {user} = useContext(AuthContext)
    const navigate = useNavigate();

    if(user){
      navigate("/home")      
    }
    
    return (
      // <>
      //   <NavbarMinimal />

      //   <div className='flex w-full items-center absolute top-[50%] left-1/2 -translate-y-1/2 -translate-x-1/2'>
      //     <LoginForm />
      //   </div>
      // </>
      <div className="flex flex-col h-dvh">
        <NavbarMinimal />      
        <div className="flex flex-col h-full justify-center">
          <LoginForm />
        </div>
        
    </div>
    );
  }
  
  export default Login;