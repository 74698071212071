import { Navbar } from "../components/custom/Navbar"
import ProfileNavigation from "../components/custom/ProfileNavigation/ProfileNavigation"
import { Separator } from "../components/ui/separator"
import PageTitle from "../components/custom/ProfileNavigation/PageTitle"
import ProfileForm from "../components/custom/Forms/ProfileForm"
import Footer from "../components/custom/Footer"

const Profile = () => {

    return (
        <div >
            <div className="bg-white sticky top-0 z-10">
                <Navbar />
            </div>
            <div className="flex">
                <div className="sticky top-0 z-1 self-start h-dvh">
                    <ProfileNavigation />
                </div>
                <div className="p-[15px] sm:p-6 w-full">
                    <PageTitle title="Profile"/>
                    <Separator className="my-3"/>
                    <ProfileForm />
                    
                    <div>
                        
                    </div>
                </div>
            </div>
            <div className="mt-36">
                <Footer></Footer>
            </div>
        </div>
        )
    }

  export default Profile