import React from 'react'
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import {useNavigate } from 'react-router-dom';
import {  } from '../components/custom/Forms/LoginForm';
import { ResetPasswordForm } from '../components/custom/ResetPasswordForm';

function ResetPassword() {
    let {user} = useContext(AuthContext)
    const navigate = useNavigate();

    if(user){
      navigate("/home")      
    }
    
    return (
      <div className='flex justify-center items-center h-dvh'>
        <ResetPasswordForm />

      </div>
    );
  }
  
  export default ResetPassword;