import { useState } from "react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "src/components/ui/alert-dialog"

import { Button } from "src/components/ui/button"
import AuthContext from "src/context/AuthContext"
import { useContext } from "react"

  export function DeleteProfile({setError}) {
    let {authTokens, logoutUser, server_address, user} = useContext(AuthContext)
    let [open, setOpen] = useState()

    let deleteUser = async() => {
        let response = await fetch(server_address+'/user/delete?', {
            method:'DELETE',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })
        
        if (response.status == 200)
        {
            //setError(false)
            logoutUser()
        }

        else {
            //setError(true)
        }
    } 

    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
        <Button variant="destructive" className="">
            Delete profile
        </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-md">Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your account and remove your data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={() => deleteUser()}>Confirm</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }