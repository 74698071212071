import React from 'react'
import { User } from 'lucide-react';
import { Button } from '../ui/button';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { LogoLink } from './LogoLink';
import {default as logoFullB} from "../../images/FD-logofullB.svg"
import {default as logoShortW} from "../../images/FD-logoshortW.svg"

export const NavbarMinimal = () => {

    return (
      
      <header className="flex justify-between flex-row px-5 h-[90px] items-center gap-5 ">
        <div >
          <Link to={"/"}>
            <img src="FD-logoshortW.svg" alt="My Happy SVG" className="h-[40px] md:hidden"/>
            <img src="FD-logofullW.svg" alt="My Happy SVG" className="hidden md:block h-[40px]"/>
          </Link>
        </div>

        

      </header>
    );
}

