import { useEffect, useState } from "react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "src/components/ui/alert-dialog"


import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/components/ui/dialog"
import { Input } from "src/components/ui/input"
import { Label } from "src/components/ui/label"

import { Button } from "src/components/ui/button"
import AuthContext from "src/context/AuthContext"
import { useContext } from "react"
import { EditSize } from "../EditSize"
import { EditCouriers } from "../EditCouriers"
import { EditPayments } from "../EditPayments"
import { ButtonLoading } from "../ButtonLoading"

export function EditOffer({offer, refresh, setRefresh}) {
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    let [open, setOpen] = useState()
    let [size, setSize] = useState(offer.size)
    let [price, setPrice] = useState(offer.price)
    let [minimumRefs, setMinimumRefs] = useState(offer.minimum_refs)
    let [couriersAccepted, setCouriersAccepted] = useState()
    let [paymentsAccepted, setPaymentsAccepted] = useState()
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(false)
    
    let [couriers, setCouriers] = useState()
    let [payments, setPayments] = useState()
    let [sizes, setSizes] = useState()
    console.log(offer)
    useEffect(() => {
        if(open){
            getCouriers()
            getPayments()
            getSizes()
            let couriers_accepted = []
            


            offer.couriers_accepted.map((c) => {
                couriers_accepted.push(c.name)
            })
            let payments_accepted = []
            offer.payments_accepted.map((c) => {
                payments_accepted.push(c.name)
            })
            setCouriersAccepted(couriers_accepted)
            setPaymentsAccepted(payments_accepted)

            // setCouriersAccepted(offer.couriers_accepted)
            // setPaymentsAccepted(offer.payments_accepted)
        }
        
    }, [open])

    let getCouriers = async () => {
        
        let response = await fetch(server_address+'/couriers', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let couriersArray = []
            data.map((courier) => {
                couriersArray.push(courier.name)
            })
            setCouriers(couriersArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    
    let getPayments = async () => {
        
        let response = await fetch(server_address+'/paymentmethods', {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            let paymentMethodsArray = []
            data.map((paymentMethod) => {
                paymentMethodsArray.push(paymentMethod.name)
            })
            setPayments(paymentMethodsArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let getSizes = async () => {
        
        let response = await fetch(server_address+'/sizes?' + new URLSearchParams({
            name: offer.name,
            category: "",
            brand: "",
            
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
           
            let data = await response.json()
            let sizesArray = []
            data.map((size) => {
                sizesArray.push(size.size)
            })
            setSizes(sizesArray)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }

    let editOffer = async() => {
        setLoading(true)
        let response = await fetch(server_address+'/marketplace/edit', {
                method:'PUT',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + String(authTokens?.access)
                },
                body:JSON.stringify({
                    'offer_id': offer.id,
                    'size': size, 
                    'price': price,
                    'minimum_refs': minimumRefs,
                    'couriers_accepted': couriersAccepted,
                    'payment_methods_accepted': paymentsAccepted
                })
            })
            
            if (response.status === 200)
            {
                setLoading(false)
                setOpen(false)
                setRefresh(!refresh)
            }
            else{
                setError(true)
            }
    } 

    return (

        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
            <Button variant="orange" className="font-bold sm:w-[100px]">
                Edit
            </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle className="text-md">Edit offer</DialogTitle>
                <DialogDescription>
                Make changes to your profile here. Click save when you're done.
                </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-2 items-center gap-4">
                <Label htmlFor="price" className="">
                    Price
                </Label>
                <Input
                    id="price"
                    defaultValue={price}
                    className="col-span-1"
                    min="1" 
                    type="number"
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                />
                </div>
                <div className="grid grid-cols-2 items-center gap-4">
                <Label htmlFor="minimum_refs" className="">
                    Minimum Refs
                </Label>
                <Input
                    id="minimum_refs"
                    defaultValue={minimumRefs}
                    className="col-span-1"
                    min="1" 
                    type="number"
                    onChange={(e) => setMinimumRefs(e.target.value)}
                    value={minimumRefs}
                />

                </div>
            </div>
            {sizes && size && setSize && <EditSize selectedSize={size} sizes={sizes} selectSize={setSize} className="text-white"/>}
            {couriers && couriersAccepted && setCouriersAccepted && <EditCouriers selectedCouriers={couriersAccepted} couriers={couriers} setCouriers={setCouriersAccepted} className="text-white"/>}
            {payments && paymentsAccepted && setPaymentsAccepted && <EditPayments selectedPaymentMethods={paymentsAccepted} paymentMethods={payments} setPaymentMethods={setPaymentsAccepted} className="text-white"/>}
            <DialogFooter>
                {loading ? <ButtonLoading /> : <Button type="submit" onClick={() => editOffer()}>Save changes</Button>}
            </DialogFooter>
            </DialogContent>
        </Dialog>
    )
  }