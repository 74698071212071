import ShoeCard from "./ShoeCard"
import { useState } from "react"
import { useContext } from "react"
import AuthContext from "src/context/AuthContext"
import { useEffect } from "react"

function Bestselling(){
    let [shoes, setShoes] = useState()
    let {authTokens, logoutUser, server_address} = useContext(AuthContext)
    useEffect(() => {
        getShoes()
    }, [])


    let getShoes = async () => {
        
        let response = await fetch(server_address+"/shoes?" + new URLSearchParams({
            name: '',
            page: 1,
            limit: 8,
            brand: '',
            order_by: 'best_selling'
        }), {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + String(authTokens?.access)
            }
        })

        
        if(response.status === 200)
        {
            let data = await response.json()
            setShoes(data.shoes)
        } else if(response.statusText === 'Unauthorizes'){
            logoutUser()
        } 
    }


    return(
            <div className=" overflow-x-auto scroll-auto min-w-[240px]">
                <p className="font-bold text-md overscroll-none">
                    Best selling pairs
                </p>

                {shoes ?
                <div className="flex flex-row scroll-auto overflow-x-auto mt-1 gap-5 ">
                    {shoes && shoes.map((shoe) => (
                        <ShoeCard shoe={shoe}></ShoeCard>
                    )
                        
                    )}
                </div> :
                <div className="w-full h-[200px] flex justify-center items-center">
                    <p>
                        No sales yet
                    </p>
                </div>
                
            }
                
            </div>
    )



    
}

export default Bestselling