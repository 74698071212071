import { Loader2 } from "lucide-react";
import { Button } from "../ui/button";
import { cn } from "src/lib/utils";

export function ButtonLoading({className}) {
    return (
      <Button disabled className={className}>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        Please wait
      </Button>
    )
  }