import { Link } from "react-router-dom"
import { Navbar } from "../components/custom/Navbar"
import Footer from "../components/custom/Footer"

const Help = () => {


    return (
        <div>
            <div className="sticky top-0 xl:col-span-2 z-10">
                <Navbar />
            </div>
            
            <div className=" p-[15px] sm:p-11">
                <ol className="flex flex-col gap-10">
                    <li>
                        <h1 className="font-bold text-md sm:text-lg">
                            How does it work?
                        </h1>
                        <div className="mt-4">
                            <p>
                                Search for a shoe, insert a bid if you want to buy or an ask if you want to sell. You can also accept an already existing offer from another user.<br /> <br />
                                After you post a bid/ask, all the sellers/buyers interested in the same pair and size as you will get notified with an email. <br /> <br />
                                When someone accepts your offer or you accept someone else's offer, You and the other user will get an email with the contact information of the other person. <br /> <br/>
                                You will then contact the other user and close the deal with the pre-established conditions in the offer (price/couriers/payments) <br /> <br />
                                If there is a problem with your deal you will be able to report that via your profile. If no one report any problem, the deal is going to be automatically considered completed after 10 days since the offer was accepted <br /> <br />
                                Please check our rules <span></span>
                                    <Link to={"/rules"} className="underline">
                                        here
                                    </Link>
                            </p>
                        </div>
                    </li>
                    <li>
                        <h1 className="font-bold text-md sm:text-lg">
                            How to contact us
                        </h1>
                        <div className="mt-4">
                            <ol className="flex flex-col gap-2">
                                <li>
                                    <p>
                                        - Write us an email at support@finedeal.eu
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        - Join our discord server <a href="https://discord.gg/aBAu8wCGrq" className="underline">here</a> and open a ticket
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h1 className="font-bold text-md sm:text-lg">
                            Who am I?
                        </h1>
                        <div className="mt-4">
                            <ol className="flex flex-col gap-2">
                                <li>
                                    <p>
                                        Hi! I'm Andrea, I've been reselling for the last two years and during this time I noticed there wasn't any professional and simple way for resellers to connect. <br /> <br />
                                        Being tired of all the Whatsapp, Facebook or Discord groups, I decided to develop my own marketplace in the most professional way possible. <br /> <br />
                                        Fine Deal was created to be the most trasparent and efficient marketplace for resellers with all the features of a real stock exchange. <br /> <br />
                                        My goal is to create a community full of professional figures that, with the help of Fine Deal, will have the possibility to make a ton of money in the least stressful way. <br /> <br />
                                        Every suggestion is welcomed, please contact me if you have any ideas. 
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </li>
                    
                </ol>
               
            </div>
           
            <div className="mt-[400px]">
                <Footer />
            </div>
        </div>
        )
    }
  
  export default Help