import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from "./CustomAccordion";


export default function SelectDefaultCouriers({selectedCouriers, couriers, setCouriers}){
    function deleteCourier(courier){
        setCouriers(selectedCouriers.filter((a) => a === courier ? false : true))
    }
    
    return(

        <Accordion type="single" collapsible >
            <AccordionItem value="item-1" className="border-none ">

                <AccordionTrigger selectedSize={selectedCouriers.length === 1 && selectedCouriers[0] === "Default"  && "Default"} 
                    classname="">
                        <div className="flex items-center gap-4 py-0 sm:text-md">
                            <p> Default couriers </p>
                        </div>
                       
                    </AccordionTrigger>
                <AccordionContent>
                    <div className="flex flex-col gap-2">
                        {couriers && couriers.map((courier, index) => (
                            <div className="flex items-center gap-3">
                                {selectedCouriers.includes(courier) ? 
                                <button 
                                    key={index}
                                    className="w-[16px] h-[16px] bg-orange rounded-full" 
                                    onClick={() => {deleteCourier(courier)}}
                                />
                                : 
                                <button 
                                    className="w-[16px] h-[16px] bg-background border border-white rounded-full"
                                    onClick={() => {
                                        setCouriers([
                                            ...selectedCouriers,
                                            courier
                                        ])}} 
                                />}

                                <p>{courier}</p>
                            </div>
                        ))}
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>

    )
}